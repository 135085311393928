import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Accompaniment = {
  __typename?: 'Accompaniment';
  id: Scalars['Int'];
  image: Image;
  imageId: Scalars['Int'];
  title: Scalars['String'];
};

export type AdminAccompanimentOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  imageId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type AdminAccompanimentsWhereInput = {
  id?: InputMaybe<IntFilter>;
  imageId?: InputMaybe<IntFilter>;
  title?: InputMaybe<StringFilter>;
};

export type AdminBeerAttributeCreateInput = {
  acidity?: InputMaybe<Scalars['Int']>;
  bitterness?: InputMaybe<Scalars['Int']>;
  citric?: InputMaybe<Scalars['Int']>;
  color?: InputMaybe<Scalars['Int']>;
  floral?: InputMaybe<Scalars['Int']>;
  fruity?: InputMaybe<Scalars['Int']>;
  liquorous?: InputMaybe<Scalars['Int']>;
  malty?: InputMaybe<Scalars['Int']>;
  productId: Scalars['Int'];
  special?: InputMaybe<Scalars['Int']>;
};

export type AdminBeerAttributeOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
};

export type AdminBeerAttributeUpdateInputData = {
  acidity?: InputMaybe<Scalars['Int']>;
  bitterness?: InputMaybe<Scalars['Int']>;
  citric?: InputMaybe<Scalars['Int']>;
  color?: InputMaybe<Scalars['Int']>;
  floral?: InputMaybe<Scalars['Int']>;
  fruity?: InputMaybe<Scalars['Int']>;
  liquorous?: InputMaybe<Scalars['Int']>;
  malty?: InputMaybe<Scalars['Int']>;
  special?: InputMaybe<Scalars['Int']>;
};

export type AdminBeerAttributesWhereInput = {
  id?: InputMaybe<IntFilter>;
  productId?: InputMaybe<IntFilter>;
};

export type AdminBeerDetailCreateInput = {
  category?: InputMaybe<BeerDetailCategory>;
  description: Scalars['String'];
  graduation: Scalars['Float'];
  ibu: Scalars['Float'];
};

export type AdminBeerDetailOrderByWithRelationInput = {
  category?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  graduation?: InputMaybe<SortOrder>;
  ibu?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type AdminBeerDetailUpdateInputData = {
  category?: InputMaybe<BeerDetailCategory>;
  description: Scalars['String'];
  graduation: Scalars['Float'];
  ibu: Scalars['Float'];
};

export type AdminBeerDetailsWhereInput = {
  category?: InputMaybe<BeerDetailCategoryFilter>;
  description?: InputMaybe<StringFilter>;
  graduation?: InputMaybe<FloatFilter>;
  ibu?: InputMaybe<FloatFilter>;
  id?: InputMaybe<IntFilter>;
};

export type AdminBrandCreateInput = {
  abbreviation: Scalars['String'];
  bannerImageId?: InputMaybe<Scalars['Int']>;
  imageId?: InputMaybe<Scalars['Int']>;
  isMainBrand?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  slideImageId?: InputMaybe<Scalars['Int']>;
};

export type AdminBrandOrderByWithRelationInput = {
  abbreviation?: InputMaybe<SortOrder>;
  bannerImageId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  slideImageId?: InputMaybe<SortOrder>;
};

export type AdminBrandUpdateInputData = {
  abbreviation?: InputMaybe<Scalars['String']>;
  bannerImageId?: InputMaybe<Scalars['Int']>;
  imageId?: InputMaybe<Scalars['Int']>;
  isMainBrand?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  slideImageId?: InputMaybe<Scalars['Int']>;
};

export type AdminBrandsWhereInput = {
  abbreviation?: InputMaybe<StringFilter>;
  bannerImageId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  imageId?: InputMaybe<IntFilter>;
  isMainBrand?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringFilter>;
  slideImageId?: InputMaybe<Scalars['Int']>;
};

export type AdminImageCreateInput = {
  hdId: Scalars['Int'];
  nhdId: Scalars['Int'];
  sourceId: Scalars['Int'];
  stId: Scalars['Int'];
};

export type AdminImageOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrderCore>;
  hdId?: InputMaybe<SortOrderCore>;
  id?: InputMaybe<SortOrderCore>;
  nhdId?: InputMaybe<SortOrderCore>;
  sourceId?: InputMaybe<SortOrderCore>;
  stId?: InputMaybe<SortOrderCore>;
  updatedAt?: InputMaybe<SortOrderCore>;
};

export type AdminImageUpdateInputData = {
  hdId: Scalars['Int'];
  nhdId: Scalars['Int'];
  sourceId: Scalars['Int'];
  stId: Scalars['Int'];
};

export type AdminImagesWhereInput = {
  createdAt?: InputMaybe<DateFilterCore>;
  hdId?: InputMaybe<IntFilterCore>;
  id?: InputMaybe<IntFilterCore>;
  nhdId?: InputMaybe<IntFilterCore>;
  sourceId?: InputMaybe<IntFilterCore>;
  stId?: InputMaybe<IntFilterCore>;
  updatedAt?: InputMaybe<DateFilterCore>;
};

export type AdminImportPackagedProductCreateInput = {
  shortCode: Scalars['String'];
};

export type AdminMediaCreateInput = {
  blobUrl: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type AdminMediaOrderByWithRelationInput = {
  blobUrl?: InputMaybe<SortOrderCore>;
  createdAt?: InputMaybe<SortOrderCore>;
  id?: InputMaybe<SortOrderCore>;
  name?: InputMaybe<SortOrderCore>;
  type?: InputMaybe<SortOrderCore>;
  updatedAt?: InputMaybe<SortOrderCore>;
};

export type AdminMediaUpdateInputData = {
  blobUrl: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type AdminMediasWhereInput = {
  blobUrl?: InputMaybe<StringFilterCore>;
  createdAt?: InputMaybe<DateFilterCore>;
  id?: InputMaybe<IntFilterCore>;
  name?: InputMaybe<StringFilterCore>;
  type?: InputMaybe<StringFilterCore>;
  updatedAt?: InputMaybe<DateFilterCore>;
};

export type AdminPackagedBeerDetailCreateInput = {
  container: PackagedBeerDetailsContainer;
  packagedProductId: Scalars['Int'];
  unitsNb?: InputMaybe<Scalars['Int']>;
  volume: Scalars['Float'];
};

export type AdminPackagedBeerDetailUpdateInputData = {
  container: PackagedBeerDetailsContainer;
  unitsNb?: InputMaybe<Scalars['Int']>;
  volume: Scalars['Float'];
};

export type AdminPackagedLampDetailUpdateInputData = {
  consumption?: InputMaybe<Scalars['Float']>;
  dimensions?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type AdminPackagedLampDetailsCreateInput = {
  consumption?: InputMaybe<Scalars['Float']>;
  dimensions?: InputMaybe<Scalars['String']>;
  packagedProductId: Scalars['Int'];
  weight?: InputMaybe<Scalars['Int']>;
};

export type AdminPackagedPosDisplayDetailUpdateInputData = {
  dimensions?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<Scalars['Int']>;
};

export type AdminPackagedPosDisplayDetailsCreateInput = {
  dimensions?: InputMaybe<Scalars['String']>;
  packagedProductId: Scalars['Int'];
  units?: InputMaybe<Scalars['Int']>;
};

export type AdminPackagedProductCreateInput = {
  croppedImageId?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['Int']>;
  isActive: Scalars['Boolean'];
  isMain?: Scalars['Boolean'];
  name: Scalars['String'];
  productId: Scalars['Int'];
  slideId?: InputMaybe<Scalars['Int']>;
  staticSlideTypeId?: InputMaybe<Scalars['Int']>;
};

export type AdminPackagedProductOrderByWithRelationInput = {
  croppedImageId?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageId?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  isMain?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  slideId?: InputMaybe<SortOrder>;
};

export type AdminPackagedProductUpdateInputData = {
  croppedImageId?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isMain?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  productId: Scalars['Int'];
  slideId?: InputMaybe<Scalars['Int']>;
  staticSlideTypeId?: InputMaybe<Scalars['Int']>;
};

export type AdminPackagedProductWhereUniqueInput = {
  id: Scalars['Int'];
};

export type AdminPackagedProductsWhereInput = {
  AND?: InputMaybe<Array<AdminPackagedProductsWhereInput>>;
  NOT?: InputMaybe<Array<AdminPackagedProductsWhereInput>>;
  OR?: InputMaybe<Array<AdminPackagedProductsWhereInput>>;
  croppedImageId?: InputMaybe<IntFilter>;
  externalId?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  imageId?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isMain?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringFilter>;
  product?: InputMaybe<AdminProductsWhereInput>;
  productId?: InputMaybe<IntFilter>;
  slideId?: InputMaybe<IntFilter>;
  staticSlideTypeId?: InputMaybe<IntFilter>;
};

export type AdminPackagedShowcaseDetailUpdateInputData = {
  bottleUnits?: InputMaybe<Scalars['Int']>;
  canUnits?: InputMaybe<Scalars['Int']>;
  consumptionPerDay: Scalars['Float'];
  dimensions?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<PackagedShowcaseDetailsFormat>;
  volume: Scalars['Float'];
  weight?: InputMaybe<Scalars['Int']>;
};

export type AdminPackagedShowcaseDetailsCreateInput = {
  bottleUnits?: InputMaybe<Scalars['Int']>;
  canUnits?: InputMaybe<Scalars['Int']>;
  consumptionPerDay: Scalars['Float'];
  dimensions?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<PackagedShowcaseDetailsFormat>;
  packagedProductId: Scalars['Int'];
  volume: Scalars['Float'];
  weight?: InputMaybe<Scalars['Int']>;
};

export type AdminPackagedTapColumnDetailUpdateInputData = {
  dimensions?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  isIlluminated: Scalars['Boolean'];
  tapBase?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type AdminPackagedTapColumnDetailsCreateInput = {
  dimensions?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  isIlluminated: Scalars['Boolean'];
  packagedProductId: Scalars['Int'];
  tapBase?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type AdminProductAccompanimentCreateInput = {
  accompanimentId: Scalars['Int'];
  productId: Scalars['Int'];
};

export type AdminProductAccompanimentOrderByWithRelationInput = {
  accompanimentId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
};

export type AdminProductAccompanimentsWhereInput = {
  accompanimentId?: InputMaybe<IntFilter>;
  productId?: InputMaybe<IntFilter>;
};

export type AdminProductCreateInput = {
  augmentedRealityUrl?: InputMaybe<Scalars['String']>;
  beerDetailsId?: InputMaybe<Scalars['Int']>;
  brandId?: InputMaybe<Scalars['Int']>;
  category: ProductCategory;
  imageId?: InputMaybe<Scalars['Int']>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  productCode?: InputMaybe<Scalars['String']>;
  shortCode?: InputMaybe<Scalars['String']>;
  slideId?: InputMaybe<Scalars['Int']>;
  tags: Array<Scalars['String']>;
};

export type AdminProductOrderByWithRelationInput = {
  brandId?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  slideId?: InputMaybe<SortOrder>;
};

export type AdminProductUpdateInputData = {
  augmentedRealityUrl?: InputMaybe<Scalars['String']>;
  beerDetailsId?: InputMaybe<Scalars['Int']>;
  brandId?: InputMaybe<Scalars['Int']>;
  category: ProductCategory;
  chartImageId?: InputMaybe<Scalars['Int']>;
  imageId?: InputMaybe<Scalars['Int']>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  productCode?: InputMaybe<Scalars['String']>;
  shortCode?: InputMaybe<Scalars['String']>;
  slideId?: InputMaybe<Scalars['Int']>;
  tags: Array<Scalars['String']>;
};

export type AdminProductsWhereInput = {
  AND?: InputMaybe<Array<AdminProductsWhereInput>>;
  NOT?: InputMaybe<Array<AdminProductsWhereInput>>;
  OR?: InputMaybe<Array<AdminProductsWhereInput>>;
  brandId?: InputMaybe<IntFilter>;
  category?: InputMaybe<ProductCategoryFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringFilter>;
  packagedProducts?: InputMaybe<PackagedProductListRelationFilterInput>;
  productCode?: InputMaybe<StringFilter>;
  tags?: InputMaybe<TagsFilterInput>;
};

export type AdminSlideCreateInput = {
  product: ProductCreateNestedOneWithoutSlideInput;
  slideImageId: Scalars['Int'];
  status: SlideStatus;
};

export type AdminSlideOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slideImageId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type AdminSlideUpdateInputData = {
  slideImageId?: InputMaybe<Scalars['Int']>;
  slidePartProductId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<SlideStatus>;
};

export type AdminSlidesWhereInput = {
  createdAt?: InputMaybe<DateFilter>;
  id?: InputMaybe<IntFilter>;
  slideImageId?: InputMaybe<IntFilter>;
  status?: InputMaybe<SlideStatusFilter>;
};

export type AdminStaticSlideCreateInput = {
  enabledByDefault: Scalars['Boolean'];
  imageId: Scalars['Int'];
  order?: InputMaybe<Scalars['Int']>;
  typeId: Scalars['Int'];
};

export type AdminStaticSlideOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  typeId?: InputMaybe<SortOrder>;
};

export type AdminStaticSlideTypeCreateInput = {
  editable?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  packagedProducts?: InputMaybe<PackagedProductCreateNestedManyWithoutStaticSlideTypeInput>;
  productCategory?: InputMaybe<ProductCategory>;
  type: Scalars['String'];
};

export type AdminStaticSlideTypeOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type AdminStaticSlideTypeUpdateInputData = {
  editable?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  packagedProducts?: InputMaybe<PackagedProductCreateNestedManyWithoutStaticSlideTypeInput>;
  productCategory?: InputMaybe<ProductCategory>;
  type?: InputMaybe<Scalars['String']>;
};

export type AdminStaticSlideTypesWhereInput = {
  editable?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<IntFilter>;
  type?: InputMaybe<StringFilter>;
};

export type AdminStaticSlideUpdateInputData = {
  enabledByDefault?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type AdminStaticSlidesWhereInput = {
  id?: InputMaybe<IntFilter>;
  typeId?: InputMaybe<IntFilter>;
};

export type AdminUserCreateInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  managerId?: InputMaybe<Scalars['String']>;
  oktaId?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  role: Role;
  title?: InputMaybe<Scalars['String']>;
  userCode: Scalars['String'];
};

export type AdminUserOrderByWithRelationInput = {
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  managerId?: InputMaybe<SortOrder>;
  oktaId?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  userCode?: InputMaybe<SortOrder>;
};

export type AdminUserUpdateInputData = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  managerId?: InputMaybe<Scalars['String']>;
  oktaId?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role>;
  title?: InputMaybe<Scalars['String']>;
  userCode?: InputMaybe<Scalars['String']>;
};

export type AdminUsersWhereInput = {
  AND?: InputMaybe<Array<AdminUsersWhereInput>>;
  NOT?: InputMaybe<Array<AdminUsersWhereInput>>;
  OR?: InputMaybe<Array<AdminUsersWhereInput>>;
  email?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringFilter>;
  managerId?: InputMaybe<StringFilter>;
  oktaId?: InputMaybe<StringFilter>;
  role?: InputMaybe<UserRoleFilter>;
  userCode?: InputMaybe<StringFilter>;
};

export type BeerAttribute = {
  __typename?: 'BeerAttribute';
  acidity?: Maybe<Scalars['Int']>;
  bitterness?: Maybe<Scalars['Int']>;
  citric?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['Int']>;
  floral?: Maybe<Scalars['Int']>;
  fruity?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  liquorous?: Maybe<Scalars['Int']>;
  malty?: Maybe<Scalars['Int']>;
  productId: Scalars['Int'];
  special?: Maybe<Scalars['Int']>;
};

export enum BeerDetailCategory {
  Craft = 'CRAFT',
  Extra = 'EXTRA',
  Flavor = 'FLAVOR',
  GlutenFree = 'GLUTEN_FREE',
  International = 'INTERNATIONAL',
  Mixed = 'MIXED',
  NationalNonAlcoholic = 'NATIONAL_NON_ALCOHOLIC',
  NationalWithAlcohol = 'NATIONAL_WITH_ALCOHOL',
  Water = 'WATER'
}

export type BeerDetailCategoryFilter = {
  in?: InputMaybe<Array<BeerDetailCategory>>;
};

export type BeerDetailCreateInput = {
  description: Scalars['String'];
  graduation: Scalars['Float'];
  /** International Bitterness Units */
  ibu: Scalars['Float'];
};

export type BeerDetails = {
  __typename?: 'BeerDetails';
  category?: Maybe<BeerDetailCategory>;
  description: Scalars['String'];
  graduation: Scalars['Float'];
  /** International Bitterness Units */
  ibu: Scalars['Float'];
  id: Scalars['Int'];
};

export type BeerDetailsCreateNestedInput = {
  create?: InputMaybe<BeerDetailCreateInput>;
};

export type BookmarkedConnectData = {
  connect?: InputMaybe<Array<IdInput>>;
  disconnect?: InputMaybe<Array<IdInput>>;
};

export type BookmarkedProductsInput = {
  bookmarkedProducts: BookmarkedConnectData;
};

export type Brand = {
  __typename?: 'Brand';
  abbreviation: Scalars['String'];
  bannerImage?: Maybe<Image>;
  bannerImageId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['Int']>;
  isMainBrand: Scalars['Boolean'];
  name: Scalars['String'];
  order: Scalars['Int'];
  products: Array<Product>;
  slideImage?: Maybe<Image>;
  slideImageId?: Maybe<Scalars['Int']>;
};

export type BrandConnectNestedInput = {
  connect: BrandWhereUniqueInput;
};

export type BrandCreateInput = {
  abbreviation: Scalars['String'];
  name: Scalars['String'];
};

export type BrandDeleteInput = {
  id: Scalars['Int'];
};

export type BrandWhereInput = {
  AND?: InputMaybe<Array<BrandWhereInput>>;
  abbreviation?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  isMainBrand?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringFilter>;
};

export type BrandWhereUniqueInput = {
  id: Scalars['Int'];
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export enum ClientType {
  Client = 'CLIENT',
  ClientWithoutKegbase = 'CLIENT_WITHOUT_KEGBASE',
  CompetitorClient = 'COMPETITOR_CLIENT',
  NotClient = 'NOT_CLIENT'
}

export type ClientTypeFilter = {
  in?: InputMaybe<Array<ClientType>>;
  notIn?: InputMaybe<Array<ClientType>>;
};

export type Contact = {
  __typename?: 'Contact';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isPrincipal?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type Contract = {
  __typename?: 'Contract';
  endDate: Scalars['DateTime'];
  id: Scalars['Int'];
  payedAmount: Scalars['Float'];
  startDate: Scalars['DateTime'];
  totalAmount: Scalars['Float'];
  type?: Maybe<ContractType>;
};

export enum ContractType {
  Advertising = 'ADVERTISING',
  Loan = 'LOAN'
}

export type Coordinates = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CustomCommercialCondition = {
  __typename?: 'CustomCommercialCondition';
  name: Scalars['String'];
  price: Scalars['Float'];
  proposalId: Scalars['Int'];
  unitsNb: Scalars['Int'];
};

export type CustomCommercialConditionsInput = {
  name: Scalars['String'];
  price: Scalars['Float'];
  unitsNb: Scalars['Int'];
};

export type DateFilter = {
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
};

export type DateFilterCore = {
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
};

export type DeleteCount = {
  __typename?: 'DeleteCount';
  count: Scalars['Int'];
};

export type DetailsUnion = BeerDetails;

export enum DiscountType {
  EuroPerLiter = 'EURO_PER_LITER',
  EuroPerUnit = 'EURO_PER_UNIT',
  Percentage = 'PERCENTAGE',
  XY = 'X_Y'
}

export type DynamicSlide = {
  __typename?: 'DynamicSlide';
  id: Scalars['Int'];
  order: Scalars['Int'];
  pointOfSaleId?: Maybe<Scalars['Int']>;
  slide: Image;
  slideId: Scalars['Int'];
  type: PointOfSaleSlideType;
};

export type EnumPackagedBeerDetailsContainerFilter = {
  equals?: InputMaybe<PackagedBeerDetailsContainer>;
  in?: InputMaybe<Array<PackagedBeerDetailsContainer>>;
  notIn?: InputMaybe<Array<PackagedBeerDetailsContainer>>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type GuaranteeInput = {
  firstGuarantee?: InputMaybe<TypeAmountInput>;
  secondGuarantee?: InputMaybe<TypeAmountInput>;
};

export enum GuaranteeType {
  Bank = 'BANK',
  None = 'NONE',
  Personal = 'PERSONAL'
}

export type IdInput = {
  id: Scalars['Int'];
};

export type IdName = {
  __typename?: 'IdName';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Image = {
  __typename?: 'Image';
  hd: Media;
  hdId: Scalars['Int'];
  id: Scalars['Int'];
  nhd: Media;
  nhdId: Scalars['Int'];
  slideParts: Array<SlidePart>;
  slides: Array<Slide>;
  source: Media;
  sourceId: Scalars['Int'];
  src: Scalars['String'];
  st: Media;
  stId: Scalars['Int'];
};

export type ImportMonthLiters = {
  import?: InputMaybe<Scalars['Float']>;
  liters?: InputMaybe<Scalars['Float']>;
  month?: InputMaybe<Scalars['Float']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
};

export type IntFilterCore = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
};

export type Kpi = {
  __typename?: 'KPI';
  averagePricePerHectoliter: Scalars['Float'];
  averagePricePerHectoliterByManager: Scalars['Float'];
};

export type Loan = {
  import?: InputMaybe<Scalars['Float']>;
  liters?: InputMaybe<Scalars['Float']>;
  litersPerYear?: InputMaybe<Scalars['Float']>;
  month?: InputMaybe<Scalars['Float']>;
};

export type Location = {
  __typename?: 'Location';
  address: Scalars['String'];
  id: Scalars['Int'];
  isBuyer: Scalars['Boolean'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  pointOfSaleId: Scalars['Int'];
};

export type LocationWhereInput = {
  AND?: InputMaybe<Array<LocationWhereInput>>;
  NOT?: InputMaybe<Array<LocationWhereInput>>;
  OR?: InputMaybe<Array<LocationWhereInput>>;
  address?: InputMaybe<StringFilter>;
  isBuyer?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  pointOfSale?: InputMaybe<PointOfSaleWhereInput>;
};

export type Media = {
  __typename?: 'Media';
  blobUrl: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type MonthLiters = {
  liters?: InputMaybe<Scalars['Float']>;
  month?: InputMaybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addProposalProducts?: Maybe<Array<ProposalSlide>>;
  adminCreateBeerAttribute: BeerAttribute;
  adminCreateBeerDetail: BeerDetails;
  adminCreateBrand: Brand;
  adminCreateImage: Image;
  adminCreateMedia: Media;
  adminCreatePackagedBeerDetail: PackagedBeerDetails;
  adminCreatePackagedLampDetail: PackagedLampDetails;
  adminCreatePackagedPosDisplayDetail: PackagedPosDisplayDetails;
  adminCreatePackagedProduct: PackagedProduct;
  adminCreatePackagedShowcaseDetail: PackagedShowcaseDetails;
  adminCreatePackagedTapColumnDetail: PackagedTapColumnDetails;
  adminCreateProduct: Product;
  adminCreateProductAccompaniment: ProductAccompaniment;
  adminCreateSlide: Slide;
  adminCreateStaticSlide: StaticSlide;
  adminCreateStaticSlideType: StaticSlideTypeNew;
  adminCreateUser: User;
  adminDeleteBeerDetail: BeerDetails;
  adminDeleteBrand: Brand;
  adminDeleteImage: Image;
  adminDeleteMedia: Media;
  adminDeletePackagedBeerDetail: PackagedBeerDetails;
  adminDeletePackagedLampDetail: PackagedLampDetails;
  adminDeletePackagedPosDisplayDetail: PackagedPosDisplayDetails;
  adminDeletePackagedProduct: PackagedProduct;
  adminDeletePackagedShowcaseDetail: PackagedShowcaseDetails;
  adminDeletePackagedTapColumnDetail: PackagedTapColumnDetails;
  adminDeleteProduct: Product;
  adminDeleteProductAccompaniment: ProductAccompaniment;
  adminDeleteSlide: Slide;
  adminDeleteStaticSlide: StaticSlide;
  adminDeleteStaticSlideType: StaticSlideTypeNew;
  adminDeleteUser: User;
  adminImportPackagedProduct: Array<Scalars['String']>;
  adminUpdateBeerAttribute: BeerAttribute;
  adminUpdateBeerDetail: BeerDetails;
  adminUpdateBrand: Brand;
  adminUpdateImage: Image;
  adminUpdateMedia: Media;
  adminUpdatePackagedBeerDetail: PackagedBeerDetails;
  adminUpdatePackagedLampDetail: PackagedLampDetails;
  adminUpdatePackagedPosDisplayDetail: PackagedPosDisplayDetails;
  adminUpdatePackagedProduct: PackagedProduct;
  adminUpdatePackagedShowcaseDetail: PackagedShowcaseDetails;
  adminUpdatePackagedTapColumnDetail: PackagedTapColumnDetails;
  adminUpdateProduct: Product;
  adminUpdateSlide: Slide;
  adminUpdateStaticSlide: StaticSlide;
  adminUpdateStaticSlideType: StaticSlideTypeNew;
  adminUpdateUser: User;
  bookmarkProducts?: Maybe<Product>;
  createBrand: Brand;
  createIfNotExist: User;
  createNews: News;
  createNotBeerSlides: Array<PointOfSaleSlide>;
  createNotBeerSlidesNew: Array<DynamicSlide>;
  createPointOfSaleSlides: Array<PointOfSaleSlide>;
  createPointOfSaleVisit: Scalars['String'];
  createProductBeer: Product;
  createProposal: Proposal;
  createSherpaPointOfSale: PointOfSale;
  createShortProposal: Proposal;
  createSlide: Slide;
  createTraining: Training;
  deleteBrand: Brand;
  deleteNews: DeleteCount;
  deleteSlides: Slide;
  deleteStaticSlides: DeleteCount;
  deleteTrainings: DeleteCount;
  generateCommercialConditions: Array<ProposalSlide>;
  generateProposalPdf: Proposal;
  importPointOfSaleFromSherpa: PointOfSale;
  removeProposalProducts: Array<ProposalAssortment>;
  updatePointOfSaleVisit: Scalars['String'];
  updateProposalProducts: Array<ProposalAssortment>;
  updateProposalSlides: Array<ProposalSlide>;
  updateSherpaPointOfSale: PointOfSale;
  updateSlide: Slide;
  upsertCommercialConditions: Array<ProposalSlide>;
};


export type MutationAddProposalProductsArgs = {
  brandId: Scalars['Int'];
  products: Array<ProductIdAndType>;
  proposalId: Scalars['Int'];
};


export type MutationAdminCreateBeerAttributeArgs = {
  data: AdminBeerAttributeCreateInput;
};


export type MutationAdminCreateBeerDetailArgs = {
  data: AdminBeerDetailCreateInput;
};


export type MutationAdminCreateBrandArgs = {
  data: AdminBrandCreateInput;
};


export type MutationAdminCreateImageArgs = {
  data: AdminImageCreateInput;
};


export type MutationAdminCreateMediaArgs = {
  data: AdminMediaCreateInput;
};


export type MutationAdminCreatePackagedBeerDetailArgs = {
  data: AdminPackagedBeerDetailCreateInput;
};


export type MutationAdminCreatePackagedLampDetailArgs = {
  data: AdminPackagedLampDetailsCreateInput;
};


export type MutationAdminCreatePackagedPosDisplayDetailArgs = {
  data: AdminPackagedPosDisplayDetailsCreateInput;
};


export type MutationAdminCreatePackagedProductArgs = {
  data: AdminPackagedProductCreateInput;
};


export type MutationAdminCreatePackagedShowcaseDetailArgs = {
  data: AdminPackagedShowcaseDetailsCreateInput;
};


export type MutationAdminCreatePackagedTapColumnDetailArgs = {
  data: AdminPackagedTapColumnDetailsCreateInput;
};


export type MutationAdminCreateProductArgs = {
  data: AdminProductCreateInput;
};


export type MutationAdminCreateProductAccompanimentArgs = {
  data: AdminProductAccompanimentCreateInput;
};


export type MutationAdminCreateSlideArgs = {
  data: AdminSlideCreateInput;
};


export type MutationAdminCreateStaticSlideArgs = {
  data: AdminStaticSlideCreateInput;
};


export type MutationAdminCreateStaticSlideTypeArgs = {
  data: AdminStaticSlideTypeCreateInput;
};


export type MutationAdminCreateUserArgs = {
  data: AdminUserCreateInput;
};


export type MutationAdminDeleteBeerDetailArgs = {
  id: Scalars['Int'];
};


export type MutationAdminDeleteBrandArgs = {
  id: Scalars['Int'];
};


export type MutationAdminDeleteImageArgs = {
  id: Scalars['Int'];
};


export type MutationAdminDeleteMediaArgs = {
  id: Scalars['Int'];
};


export type MutationAdminDeletePackagedBeerDetailArgs = {
  id: Scalars['Int'];
};


export type MutationAdminDeletePackagedLampDetailArgs = {
  id: Scalars['Int'];
};


export type MutationAdminDeletePackagedPosDisplayDetailArgs = {
  id: Scalars['Int'];
};


export type MutationAdminDeletePackagedProductArgs = {
  id: Scalars['Int'];
};


export type MutationAdminDeletePackagedShowcaseDetailArgs = {
  id: Scalars['Int'];
};


export type MutationAdminDeletePackagedTapColumnDetailArgs = {
  id: Scalars['Int'];
};


export type MutationAdminDeleteProductArgs = {
  id: Scalars['Int'];
};


export type MutationAdminDeleteProductAccompanimentArgs = {
  id: Scalars['Int'];
};


export type MutationAdminDeleteSlideArgs = {
  id: Scalars['Int'];
};


export type MutationAdminDeleteStaticSlideArgs = {
  id: Scalars['Int'];
};


export type MutationAdminDeleteStaticSlideTypeArgs = {
  id: Scalars['Int'];
};


export type MutationAdminDeleteUserArgs = {
  id: Scalars['Int'];
};


export type MutationAdminImportPackagedProductArgs = {
  data: AdminImportPackagedProductCreateInput;
};


export type MutationAdminUpdateBeerAttributeArgs = {
  data: AdminBeerAttributeUpdateInputData;
  id: Scalars['Int'];
};


export type MutationAdminUpdateBeerDetailArgs = {
  data: AdminBeerDetailUpdateInputData;
  id: Scalars['Int'];
};


export type MutationAdminUpdateBrandArgs = {
  data: AdminBrandUpdateInputData;
  id: Scalars['Int'];
};


export type MutationAdminUpdateImageArgs = {
  data: AdminImageUpdateInputData;
  id: Scalars['Int'];
};


export type MutationAdminUpdateMediaArgs = {
  data: AdminMediaUpdateInputData;
  id: Scalars['Int'];
};


export type MutationAdminUpdatePackagedBeerDetailArgs = {
  data: AdminPackagedBeerDetailUpdateInputData;
  id: Scalars['Int'];
};


export type MutationAdminUpdatePackagedLampDetailArgs = {
  data: AdminPackagedLampDetailUpdateInputData;
  id: Scalars['Int'];
};


export type MutationAdminUpdatePackagedPosDisplayDetailArgs = {
  data: AdminPackagedPosDisplayDetailUpdateInputData;
  id: Scalars['Int'];
};


export type MutationAdminUpdatePackagedProductArgs = {
  data: AdminPackagedProductUpdateInputData;
  id: Scalars['Int'];
};


export type MutationAdminUpdatePackagedShowcaseDetailArgs = {
  data: AdminPackagedShowcaseDetailUpdateInputData;
  id: Scalars['Int'];
};


export type MutationAdminUpdatePackagedTapColumnDetailArgs = {
  data: AdminPackagedTapColumnDetailUpdateInputData;
  id: Scalars['Int'];
};


export type MutationAdminUpdateProductArgs = {
  data: AdminProductUpdateInputData;
  id: Scalars['Int'];
};


export type MutationAdminUpdateSlideArgs = {
  data: AdminSlideUpdateInputData;
  id: Scalars['Int'];
};


export type MutationAdminUpdateStaticSlideArgs = {
  data: AdminStaticSlideUpdateInputData;
  id: Scalars['Int'];
};


export type MutationAdminUpdateStaticSlideTypeArgs = {
  data: AdminStaticSlideTypeUpdateInputData;
  id: Scalars['Int'];
};


export type MutationAdminUpdateUserArgs = {
  data: AdminUserUpdateInputData;
  id: Scalars['Int'];
};


export type MutationBookmarkProductsArgs = {
  data: BookmarkedProductsInput;
};


export type MutationCreateBrandArgs = {
  brand: BrandCreateInput;
};


export type MutationCreateIfNotExistArgs = {
  email: Scalars['String'];
  oktaId: Scalars['String'];
  updateOktaId?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateNewsArgs = {
  data: NewsCreateInput;
};


export type MutationCreateNotBeerSlidesArgs = {
  proposalId: Scalars['Int'];
};


export type MutationCreateNotBeerSlidesNewArgs = {
  proposalId: Scalars['Int'];
};


export type MutationCreatePointOfSaleSlidesArgs = {
  pointOfSaleId: Scalars['Int'];
  type?: InputMaybe<Array<PointOfSaleSlideType>>;
};


export type MutationCreatePointOfSaleVisitArgs = {
  data: PointOfSaleVisitInput;
  where: PointOfSaleUniqueInput;
};


export type MutationCreateProductBeerArgs = {
  beerDetails: BeerDetailsCreateNestedInput;
  brand: BrandConnectNestedInput;
  name: Scalars['String'];
  productCode?: InputMaybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
};


export type MutationCreateProposalArgs = {
  displayedTitle?: InputMaybe<Scalars['String']>;
  expireAt: Scalars['DateTime'];
  pointOfSaleId?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
};


export type MutationCreateSherpaPointOfSaleArgs = {
  data: PointOfSaleCreateInput;
};


export type MutationCreateShortProposalArgs = {
  displayedTitle?: InputMaybe<Scalars['String']>;
  isDirectBilling: Scalars['Boolean'];
  isTankDirectBilling?: InputMaybe<Scalars['Boolean']>;
  pointOfSaleId?: InputMaybe<Scalars['Int']>;
  products: Array<ShortProposalProductInput>;
  provinceNumber?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateSlideArgs = {
  product?: InputMaybe<ProductCreateNestedOneWithoutSlideInput>;
  slideImageId: Scalars['Int'];
  slideParts?: InputMaybe<SlidePartCreateNestedManyWithoutSlideInput>;
  status: SlideStatus;
};


export type MutationCreateTrainingArgs = {
  data: TrainingCreateInput;
};


export type MutationDeleteBrandArgs = {
  brand: BrandDeleteInput;
};


export type MutationDeleteNewsArgs = {
  where: NewsWhereInput;
};


export type MutationDeleteSlidesArgs = {
  where?: InputMaybe<SlideWhereInput>;
};


export type MutationDeleteStaticSlidesArgs = {
  where: StaticSlideWhereInput;
};


export type MutationDeleteTrainingsArgs = {
  where: TrainingWhereInput;
};


export type MutationGenerateCommercialConditionsArgs = {
  proposalId: Scalars['Int'];
};


export type MutationGenerateProposalPdfArgs = {
  where: WhereProposalInput;
};


export type MutationImportPointOfSaleFromSherpaArgs = {
  externalId: Scalars['String'];
};


export type MutationRemoveProposalProductsArgs = {
  products: Array<ProductIdAndType>;
  proposalId: Scalars['Int'];
};


export type MutationUpdatePointOfSaleVisitArgs = {
  data: PointOfSaleVisitInput;
  externalId: Scalars['String'];
};


export type MutationUpdateProposalProductsArgs = {
  brandId: Scalars['Int'];
  products: Array<ProductIdAndType>;
  proposalId: Scalars['Int'];
};


export type MutationUpdateProposalSlidesArgs = {
  proposalId: Scalars['Int'];
  slides: Array<ProposalSlideInput>;
};


export type MutationUpdateSherpaPointOfSaleArgs = {
  data: PointOfSaleUpdateInput;
  externalId: Scalars['String'];
};


export type MutationUpdateSlideArgs = {
  slide: SlideUpdateInput;
  where: SlideWhereUniqueInput;
};


export type MutationUpsertCommercialConditionsArgs = {
  commercialConditions: Array<UpsertCommercialConditionInput>;
  customCommercialCondition?: InputMaybe<Array<CustomCommercialConditionsInput>>;
  generateSlides: Scalars['Boolean'];
  isDirectBilling: Scalars['Boolean'];
  isTankDirectBilling?: InputMaybe<Scalars['Boolean']>;
  proposalId: Scalars['Int'];
  provinceNumber?: InputMaybe<Scalars['Int']>;
  recapFields?: InputMaybe<RecapFields>;
  synthesis?: InputMaybe<SynthesisInput>;
};

export type News = {
  __typename?: 'News';
  contentId: Scalars['Int'];
  id: Scalars['Int'];
  image: Image;
  media: Media;
  order: Scalars['Int'];
  thumbnailId: Scalars['Int'];
  title: Scalars['String'];
};

export type NewsCreateInput = {
  contentId: Scalars['Int'];
  thumbnailId: Scalars['Int'];
  title: Scalars['String'];
};

export type NewsWhereInput = {
  id: IntFilter;
};

export type PackagedBeerDetails = {
  __typename?: 'PackagedBeerDetails';
  container: PackagedBeerDetailsContainer;
  id: Scalars['Int'];
  packagedProductId: Scalars['Int'];
  unitsNb?: Maybe<Scalars['Int']>;
  volume: Scalars['Float'];
};

export enum PackagedBeerDetailsContainer {
  Bottle = 'BOTTLE',
  Keg = 'KEG',
  Tank = 'TANK'
}

export type PackagedBeerDetailsNullableRelationFilter = {
  is?: InputMaybe<PackagedBeerDetailsWhereInput>;
  isNot?: InputMaybe<PackagedBeerDetailsWhereInput>;
};

export type PackagedBeerDetailsWhereInput = {
  AND?: InputMaybe<Array<PackagedBeerDetailsWhereInput>>;
  NOT?: InputMaybe<Array<PackagedBeerDetailsWhereInput>>;
  OR?: InputMaybe<Array<PackagedBeerDetailsWhereInput>>;
  container?: InputMaybe<EnumPackagedBeerDetailsContainerFilter>;
  id?: InputMaybe<IntFilter>;
  packagedProduct?: InputMaybe<PackagedProductRelationFilter>;
  packagedProductId?: InputMaybe<IntFilter>;
  volume?: InputMaybe<FloatFilter>;
};

export type PackagedDetailsUnion = PackagedBeerDetails | PackagedLampDetails | PackagedPosDisplayDetails | PackagedShowcaseDetails | PackagedTapColumnDetails;

export type PackagedLampDetails = {
  __typename?: 'PackagedLampDetails';
  consumption?: Maybe<Scalars['Float']>;
  dimensions?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  weight?: Maybe<Scalars['Int']>;
};

export type PackagedPosDisplayDetails = {
  __typename?: 'PackagedPosDisplayDetails';
  dimensions?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  units?: Maybe<Scalars['Int']>;
};

export type PackagedProduct = {
  __typename?: 'PackagedProduct';
  croppedImage?: Maybe<Image>;
  croppedImageId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['Int']>;
  isActive: Scalars['Boolean'];
  isMain: Scalars['Boolean'];
  name: Scalars['String'];
  packagedBeerDetails?: Maybe<PackagedBeerDetails>;
  packagedDetails: PackagedDetailsUnion;
  packagedLampDetails?: Maybe<PackagedLampDetails>;
  packagedPosDisplayDetails?: Maybe<PackagedPosDisplayDetails>;
  packagedProductPrice?: Maybe<PackagedProductPrice>;
  packagedShowcaseDetails?: Maybe<PackagedShowcaseDetails>;
  packagedTapColumnDetails?: Maybe<PackagedTapColumnDetails>;
  product: Product;
  productId: Scalars['Int'];
  slide?: Maybe<Slide>;
  slideId?: Maybe<Scalars['Int']>;
  staticSlideType?: Maybe<StaticSlideTypeNew>;
  staticSlideTypeId?: Maybe<Scalars['Int']>;
};


export type PackagedProductPackagedProductPriceArgs = {
  pointOfSaleId?: InputMaybe<Scalars['Float']>;
  provinceNumber?: InputMaybe<Scalars['Int']>;
};

export type PackagedProductCreateNestedManyWithoutStaticSlideTypeInput = {
  connect: Array<AdminPackagedProductWhereUniqueInput>;
};

export type PackagedProductListRelationFilterInput = {
  every?: InputMaybe<PackagedProductWhereInput>;
  none?: InputMaybe<PackagedProductWhereInput>;
  some?: InputMaybe<PackagedProductWhereInput>;
};

export type PackagedProductPrice = {
  __typename?: 'PackagedProductPrice';
  directBilling?: Maybe<Scalars['Float']>;
  greenTax?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  packagedProductId: Scalars['Int'];
  price: Scalars['Float'];
  provinceNumber?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Float']>;
};

export type PackagedProductPriceListRelationFilter = {
  every?: InputMaybe<PackagedProductPriceWhereInput>;
  none?: InputMaybe<PackagedProductPriceWhereInput>;
  some?: InputMaybe<PackagedProductPriceWhereInput>;
};

export type PackagedProductPriceWhereInput = {
  id?: InputMaybe<IntFilter>;
  packagedProductId?: InputMaybe<IntFilter>;
  price?: InputMaybe<FloatFilter>;
  provinceNumber?: InputMaybe<IntFilter>;
};

export type PackagedProductRelationFilter = {
  is?: InputMaybe<PackagedProductWhereInput>;
  isNot?: InputMaybe<PackagedProductWhereInput>;
};

export type PackagedProductWhereInput = {
  AND?: InputMaybe<Array<PackagedProductWhereInput>>;
  NOT?: InputMaybe<Array<PackagedProductWhereInput>>;
  OR?: InputMaybe<Array<PackagedProductWhereInput>>;
  externalId?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isMain?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringFilter>;
  packagedBeerDetails?: InputMaybe<PackagedBeerDetailsNullableRelationFilter>;
  packagedProductPrice?: InputMaybe<PackagedProductPriceListRelationFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<IntFilter>;
  sales?: InputMaybe<SaleListRelationFilter>;
};

export type PackagedShowcaseDetails = {
  __typename?: 'PackagedShowcaseDetails';
  bottleUnits?: Maybe<Scalars['Int']>;
  canUnits?: Maybe<Scalars['Int']>;
  consumptionPerDay?: Maybe<Scalars['Float']>;
  dimensions?: Maybe<Scalars['String']>;
  format?: Maybe<PackagedShowcaseDetailsFormat>;
  id: Scalars['Int'];
  volume?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Int']>;
};

export enum PackagedShowcaseDetailsFormat {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL'
}

export type PackagedTapColumnDetails = {
  __typename?: 'PackagedTapColumnDetails';
  dimensions?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isIlluminated: Scalars['Boolean'];
  tapBase?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

export type PointOfSale = {
  __typename?: 'PointOfSale';
  averagePricePerHectoliter?: Maybe<Scalars['Float']>;
  beerLiterAnnualSalesPotential?: Maybe<Scalars['Float']>;
  beerTAM: Scalars['Float'];
  brand?: Maybe<Brand>;
  brandId: Scalars['Float'];
  clientType: ClientType;
  companyName: Scalars['String'];
  competitionVolume?: Maybe<Scalars['Float']>;
  competitorsVolume?: Maybe<Scalars['Float']>;
  contact?: Maybe<Contact>;
  contractEnding?: Maybe<Scalars['DateTime']>;
  contracts: Array<Contract>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  id: Scalars['Int'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['Int']>;
  isActive: Scalars['Boolean'];
  laLiga?: Maybe<Scalars['Boolean']>;
  lastVisitDate?: Maybe<Scalars['DateTime']>;
  location?: Maybe<Location>;
  name: Scalars['String'];
  ownerAveragePriceByHectoliters: Scalars['Float'];
  ownerExternalId: Scalars['String'];
  ownerName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  provinceNumber: Scalars['Float'];
  readers: Array<User>;
  rentabilibar: Scalars['Boolean'];
  sales: Array<Sale>;
  segment?: Maybe<Segment>;
  segmentType?: Maybe<SegmentType>;
  slides: Array<PointOfSaleSlide>;
  taxId: Scalars['String'];
  type: Scalars['String'];
  typeName: Scalars['String'];
  underContract: Scalars['Boolean'];
  waterTAM: Scalars['Float'];
};

export type PointOfSaleCreateInput = {
  brandAbbreviation: Scalars['String'];
  city: Scalars['String'];
  classificationValue: Scalars['String'];
  companyName: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  phone: Scalars['String'];
  postalCode: Scalars['String'];
  street: Scalars['String'];
  taxId: Scalars['String'];
  typeValue: Scalars['String'];
};

export type PointOfSaleSlide = {
  __typename?: 'PointOfSaleSlide';
  id: Scalars['Int'];
  order: Scalars['Int'];
  pointOfSaleId: Scalars['Int'];
  slide: Image;
  slideId: Scalars['Int'];
  type: PointOfSaleSlideType;
};

export enum PointOfSaleSlideType {
  DynamicAssortment = 'DYNAMIC_ASSORTMENT',
  MonthlyVolume = 'MONTHLY_VOLUME',
  MonthlyVolumePerProduct = 'MONTHLY_VOLUME_PER_PRODUCT',
  NotBeerProducts = 'NOT_BEER_PRODUCTS'
}

export type PointOfSaleSlideTypeFilter = {
  in?: InputMaybe<Array<PointOfSaleSlideType>>;
};

export type PointOfSaleSlideWhereInput = {
  AND?: InputMaybe<Array<PointOfSaleSlideWhereInput>>;
  NOT?: InputMaybe<Array<PointOfSaleSlideWhereInput>>;
  OR?: InputMaybe<Array<PointOfSaleSlideWhereInput>>;
  id?: InputMaybe<IntFilter>;
  pointOfSaleId?: InputMaybe<StringFilter>;
  slideId?: InputMaybe<IntFilter>;
  type?: InputMaybe<Array<PointOfSaleSlideTypeFilter>>;
};

export type PointOfSaleUniqueInput = {
  id: Scalars['Int'];
};

export type PointOfSaleUpdateInput = {
  city?: InputMaybe<Scalars['String']>;
  classificationType?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
};

export type PointOfSaleVisit = {
  __typename?: 'PointOfSaleVisit';
  companion: Scalars['Boolean'];
  contactUser?: Maybe<User>;
  contactUserExternalId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  mode: VisitMode;
  name: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  status: VisitStatus;
  topic: Array<VisitTopic>;
  type: VisitType;
  wholesaleContactExternalId?: Maybe<Scalars['String']>;
};

export type PointOfSaleVisitInput = {
  companion?: InputMaybe<Scalars['Boolean']>;
  contactUserExternalId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  mode: VisitMode;
  startDate: Scalars['DateTime'];
  status: VisitStatus;
  topic: Array<VisitTopic>;
  type: VisitType;
  wholesaleContactExternalId?: InputMaybe<Scalars['String']>;
};

export type PointOfSaleVisitWhereInput = {
  externalId: Scalars['String'];
  startDate?: InputMaybe<DateFilter>;
};

export type PointOfSaleWhereInput = {
  AND?: InputMaybe<Array<PointOfSaleWhereInput>>;
  NOT?: InputMaybe<Array<PointOfSaleWhereInput>>;
  OR?: InputMaybe<Array<PointOfSaleWhereInput>>;
  address?: InputMaybe<StringFilter>;
  alreadyProposed?: InputMaybe<Scalars['Boolean']>;
  beerLiterAnnualSalesPotential?: InputMaybe<FloatFilter>;
  beerTAM?: InputMaybe<FloatFilter>;
  brand?: InputMaybe<BrandWhereInput>;
  brandId?: InputMaybe<IntFilter>;
  clientType?: InputMaybe<ClientTypeFilter>;
  companyName?: InputMaybe<StringFilter>;
  coordinates?: InputMaybe<Coordinates>;
  distance?: InputMaybe<IntFilter>;
  externalId?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  laLiga?: InputMaybe<Scalars['Boolean']>;
  lastVisitDate?: InputMaybe<DateFilter>;
  location?: InputMaybe<LocationWhereInput>;
  name?: InputMaybe<StringFilter>;
  remainingMonths?: InputMaybe<IntFilter>;
  rentabilibar?: InputMaybe<Scalars['Boolean']>;
  sales?: InputMaybe<SaleListRelationFilter>;
  segment?: InputMaybe<SegmentFilter>;
  segmentType?: InputMaybe<SegmentTypeFilter>;
  soldProducts?: InputMaybe<SoldProductRelationFilter>;
  taxId?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringFilter>;
  underContract?: InputMaybe<Scalars['Boolean']>;
  waterTAM?: InputMaybe<FloatFilter>;
};

export type Product = {
  __typename?: 'Product';
  accompaniments: Array<Accompaniment>;
  augmentedRealityUrl?: Maybe<Scalars['String']>;
  beerDetailsId?: Maybe<Scalars['Int']>;
  brand: Brand;
  brandId: Scalars['Int'];
  category: ProductCategory;
  details?: Maybe<DetailsUnion>;
  id: Scalars['Int'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['Int']>;
  isActive: Scalars['Boolean'];
  isBookmarked: Scalars['Boolean'];
  isPriceCustomizable: Scalars['Boolean'];
  lampDetailsId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  packagedProducts: Array<PackagedProduct>;
  posDisplayDetailsId?: Maybe<Scalars['Int']>;
  productCode?: Maybe<Scalars['String']>;
  productCodeV2?: Maybe<Scalars['String']>;
  shortCode?: Maybe<Scalars['String']>;
  showcaseDetailsId?: Maybe<Scalars['Int']>;
  slide?: Maybe<Slide>;
  slideId?: Maybe<Scalars['Int']>;
  tags: Array<Scalars['String']>;
  tapColumnDetailsId?: Maybe<Scalars['Int']>;
};


export type ProductPackagedProductsArgs = {
  container?: InputMaybe<PackagedBeerDetailsContainer>;
  provinceNumber?: InputMaybe<Scalars['Int']>;
};

export type ProductAccompaniment = {
  __typename?: 'ProductAccompaniment';
  accompanimentId: Scalars['Int'];
  id: Scalars['Int'];
  productId: Scalars['Int'];
};

export enum ProductCategory {
  Beer = 'BEER',
  Custom = 'CUSTOM',
  Lamp = 'LAMP',
  PosDisplay = 'POS_DISPLAY',
  Showcase = 'SHOWCASE',
  SpecialDecoration = 'SPECIAL_DECORATION',
  TapColumn = 'TAP_COLUMN',
  Terrace = 'TERRACE'
}

export type ProductCategoryFilter = {
  in?: InputMaybe<Array<ProductCategory>>;
};

export type ProductCreateNestedOneWithoutSlideInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
};

export type ProductIdAndType = {
  /** Mandatory for products of type "BEER" */
  container?: InputMaybe<PackagedBeerDetailsContainer>;
  productId: Scalars['Int'];
};

export type ProductOrderByWithRelationInput = {
  category?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  slideId?: InputMaybe<SortOrder>;
};

export type ProductRelationFilter = {
  is?: InputMaybe<ProductsWhereInput>;
  isNot?: InputMaybe<ProductsWhereInput>;
};

export type ProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type ProductsWhereInput = {
  AND?: InputMaybe<Array<ProductsWhereInput>>;
  NOT?: InputMaybe<Array<ProductsWhereInput>>;
  OR?: InputMaybe<Array<ProductsWhereInput>>;
  category?: InputMaybe<ProductCategoryFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isPriceCustomizable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringFilter>;
  packagedProducts?: InputMaybe<PackagedProductListRelationFilterInput>;
};

export type Proposal = {
  __typename?: 'Proposal';
  brandId?: Maybe<Scalars['Int']>;
  commercialConditions?: Maybe<Array<ProposalCommercialCondition>>;
  createdAt: Scalars['DateTime'];
  customCommercialCondition?: Maybe<Array<CustomCommercialCondition>>;
  displayedTitle?: Maybe<Scalars['String']>;
  expireAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use firstSlideTmp instead when possible */
  firstSlide?: Maybe<StaticSlide>;
  firstSlideTmp?: Maybe<Image>;
  format: ProposalFormat;
  id: Scalars['Int'];
  isDirectBilling: Scalars['Boolean'];
  isTankDirectBilling: Scalars['Boolean'];
  pdf?: Maybe<Media>;
  pdfId?: Maybe<Scalars['Int']>;
  pointOfSaleId?: Maybe<Scalars['Int']>;
  pptx?: Maybe<Media>;
  pptxId?: Maybe<Scalars['Int']>;
  proposalAssortments?: Maybe<Array<ProposalAssortment>>;
  proposalSlides?: Maybe<Array<ProposalSlide>>;
  state: ProposalStates;
  synthesis?: Maybe<ProposalSynthesis>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId: Scalars['Int'];
  uuid: Scalars['String'];
};

export type ProposalAssortment = {
  __typename?: 'ProposalAssortment';
  /** Mandatory for products of type "BEER" */
  container?: Maybe<PackagedBeerDetailsContainer>;
  id: Scalars['Int'];
  productId: Scalars['Int'];
  proposalId: Scalars['Int'];
};

export type ProposalCommercialCondition = {
  __typename?: 'ProposalCommercialCondition';
  amortizationType?: Maybe<RecallType>;
  amortizationValue?: Maybe<Scalars['Float']>;
  discountType?: Maybe<DiscountType>;
  discountValue?: Maybe<Scalars['Float']>;
  freePackagedProductUnitsNb?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  packagedProduct?: Maybe<PackagedProduct>;
  packagedProductId: Scalars['Int'];
  packagedProductPrice: Scalars['Float'];
  packagedProductUnitsNb: Scalars['Int'];
  proposalId: Scalars['Int'];
  recallType?: Maybe<RecallType>;
  recallValue?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

export enum ProposalFormat {
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT'
}

export type ProposalSlide = {
  __typename?: 'ProposalSlide';
  id: Scalars['Int'];
  image?: Maybe<Image>;
  imageId: Scalars['Int'];
  order: Scalars['Int'];
  proposalId: Scalars['Int'];
  type: ProposalSlideType;
};

export type ProposalSlideInput = {
  imageId: Scalars['Int'];
  order: Scalars['Int'];
  staticSlideTypeId?: InputMaybe<Scalars['Int']>;
  type: ProposalSlideType;
};

export enum ProposalSlideType {
  BarPlus = 'BAR_PLUS',
  Beer = 'BEER',
  Catalog = 'CATALOG',
  CommercialConditions = 'COMMERCIAL_CONDITIONS',
  DynamicAssortment = 'DYNAMIC_ASSORTMENT',
  FinancialSolutions = 'FINANCIAL_SOLUTIONS',
  MonthlyVolume = 'MONTHLY_VOLUME',
  MonthlyVolumePerProduct = 'MONTHLY_VOLUME_PER_PRODUCT',
  NotBeerProducts = 'NOT_BEER_PRODUCTS',
  Rentabilibar = 'RENTABILIBAR',
  StaticSlide = 'STATIC_SLIDE',
  Sustainability = 'SUSTAINABILITY',
  TapColumns = 'TAP_COLUMNS',
  Waters = 'WATERS'
}

export enum ProposalStates {
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED'
}

export type ProposalSynthesis = {
  __typename?: 'ProposalSynthesis';
  adsFirstGuaranteePercent?: Maybe<Scalars['Float']>;
  adsFirstGuaranteeType?: Maybe<GuaranteeType>;
  adsImport?: Maybe<Scalars['Float']>;
  adsLiters?: Maybe<Scalars['Float']>;
  adsMonth?: Maybe<Scalars['Float']>;
  adsSecondGuaranteePercent?: Maybe<Scalars['Float']>;
  adsSecondGuaranteeType?: Maybe<GuaranteeType>;
  contributionOther?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  discountOther?: Maybe<Scalars['Float']>;
  discountRappelLiters?: Maybe<Scalars['Float']>;
  discountRappelMonth?: Maybe<Scalars['Float']>;
  estimatedFreeLiters?: Maybe<Scalars['Float']>;
  estimatedLiters?: Maybe<Scalars['Float']>;
  firstGuaranteePercent?: Maybe<Scalars['Float']>;
  firstGuaranteeType?: Maybe<GuaranteeType>;
  id: Scalars['Int'];
  loanImport?: Maybe<Scalars['Float']>;
  loanLiters?: Maybe<Scalars['Float']>;
  loanLitersPerYear?: Maybe<Scalars['Float']>;
  loanMonth?: Maybe<Scalars['Float']>;
  marketingSupport?: Maybe<Scalars['Float']>;
  noChargeProducts?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  plvSupport?: Maybe<Scalars['Float']>;
  pointOfSaleSupport?: Maybe<Scalars['Float']>;
  proposalId: Scalars['Int'];
  rappel?: Maybe<Scalars['Float']>;
  rappelImport?: Maybe<Scalars['Float']>;
  rappelLiters?: Maybe<Scalars['Float']>;
  rappelMonth?: Maybe<Scalars['Float']>;
  secondGuaranteePercent?: Maybe<Scalars['Float']>;
  secondGuaranteeType?: Maybe<GuaranteeType>;
  specialDecoration?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  accompaniments: Array<Accompaniment>;
  adminAccompaniments: Array<Accompaniment>;
  adminAccompanimentsCount: Scalars['Int'];
  adminBeerAttributes: Array<BeerAttribute>;
  adminBeerAttributesCount: Scalars['Int'];
  adminBeerDetail: BeerDetails;
  adminBeerDetails: Array<BeerDetails>;
  adminBeerDetailsCount: Scalars['Int'];
  adminBrand: Brand;
  adminBrands: Array<Brand>;
  adminBrandsCount: Scalars['Int'];
  adminImage: Image;
  adminImages: Array<Image>;
  adminImagesCount: Scalars['Int'];
  adminMedia: Media;
  adminMedias: Array<Media>;
  adminMediasCount: Scalars['Int'];
  adminPackagedBeerDetail: PackagedBeerDetails;
  adminPackagedLampDetail: PackagedLampDetails;
  adminPackagedPosDisplayDetail: PackagedPosDisplayDetails;
  adminPackagedProduct: PackagedProduct;
  adminPackagedProducts: Array<PackagedProduct>;
  adminPackagedProductsCount: Scalars['Int'];
  adminPackagedShowcaseDetail: PackagedShowcaseDetails;
  adminPackagedTapColumnDetail: PackagedTapColumnDetails;
  adminProduct: Product;
  adminProductAccompaniments: Array<ProductAccompaniment>;
  adminProductAccompanimentsCount: Scalars['Int'];
  adminProducts: Array<Product>;
  adminProductsCount: Scalars['Int'];
  adminSlide: Slide;
  adminSlides: Array<Slide>;
  adminSlidesCount: Scalars['Int'];
  adminStaticSlide: StaticSlide;
  adminStaticSlideType: StaticSlideTypeNew;
  adminStaticSlideTypes: Array<StaticSlideTypeNew>;
  adminStaticSlideTypesCount: Scalars['Int'];
  adminStaticSlides: Array<StaticSlide>;
  adminStaticSlidesCount: Scalars['Int'];
  adminUser: User;
  adminUsers: Array<User>;
  adminUsersCount: Scalars['Int'];
  beerAttributes: Array<BeerAttribute>;
  brands: Array<Brand>;
  countPointOfSales: Scalars['Int'];
  image: Image;
  images: Array<Image>;
  locations: Array<Location>;
  me: User;
  media: Media;
  news: Array<News>;
  packagedProductPrices: Array<PackagedProductPrice>;
  packagedProducts: Array<PackagedProduct>;
  pointOfSale: PointOfSale;
  pointOfSaleClassification: Array<SelectionItem>;
  pointOfSaleType: Array<SelectionItem>;
  pointOfSaleVisitWholesaleContact: Array<SelectionItem>;
  pointOfSaleVisits: Array<PointOfSaleVisit>;
  pointOfSales: Array<PointOfSale>;
  pointOfSalesSlides: Array<PointOfSaleSlide>;
  products: Array<Product>;
  proposal: Proposal;
  proposalAssortments: Array<ProposalAssortment>;
  proposalCommercialConditions: Array<ProposalCommercialCondition>;
  proposalSlides: Array<ProposalSlide>;
  proposals: Array<Proposal>;
  recommendedAssortment: Array<RecommendedPackagedProduct>;
  reporting: Reporting;
  slides: Array<Slide>;
  staticSlideTypes: Array<StaticSlideTypeNew>;
  staticSlides: Array<StaticSlide>;
  trainings: Array<Training>;
  user: User;
  users: Array<User>;
};


export type QueryAdminAccompanimentsArgs = {
  orderBy?: InputMaybe<AdminAccompanimentOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminAccompanimentsWhereInput>;
};


export type QueryAdminAccompanimentsCountArgs = {
  orderBy?: InputMaybe<AdminAccompanimentOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminAccompanimentsWhereInput>;
};


export type QueryAdminBeerAttributesArgs = {
  orderBy?: InputMaybe<AdminBeerAttributeOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminBeerAttributesWhereInput>;
};


export type QueryAdminBeerAttributesCountArgs = {
  orderBy?: InputMaybe<AdminBeerAttributeOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminBeerAttributesWhereInput>;
};


export type QueryAdminBeerDetailArgs = {
  id: Scalars['Int'];
};


export type QueryAdminBeerDetailsArgs = {
  orderBy?: InputMaybe<AdminBeerDetailOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminBeerDetailsWhereInput>;
};


export type QueryAdminBeerDetailsCountArgs = {
  orderBy?: InputMaybe<AdminBeerDetailOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminBeerDetailsWhereInput>;
};


export type QueryAdminBrandArgs = {
  id: Scalars['Int'];
};


export type QueryAdminBrandsArgs = {
  orderBy?: InputMaybe<AdminBrandOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminBrandsWhereInput>;
};


export type QueryAdminBrandsCountArgs = {
  orderBy?: InputMaybe<AdminBrandOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminBrandsWhereInput>;
};


export type QueryAdminImageArgs = {
  id: Scalars['Int'];
};


export type QueryAdminImagesArgs = {
  orderBy?: InputMaybe<AdminImageOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminImagesWhereInput>;
};


export type QueryAdminImagesCountArgs = {
  orderBy?: InputMaybe<AdminImageOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminImagesWhereInput>;
};


export type QueryAdminMediaArgs = {
  id: Scalars['Int'];
};


export type QueryAdminMediasArgs = {
  orderBy?: InputMaybe<AdminMediaOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminMediasWhereInput>;
};


export type QueryAdminMediasCountArgs = {
  orderBy?: InputMaybe<AdminMediaOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminMediasWhereInput>;
};


export type QueryAdminPackagedBeerDetailArgs = {
  id: Scalars['Int'];
};


export type QueryAdminPackagedLampDetailArgs = {
  id: Scalars['Int'];
};


export type QueryAdminPackagedPosDisplayDetailArgs = {
  id: Scalars['Int'];
};


export type QueryAdminPackagedProductArgs = {
  id: Scalars['Int'];
};


export type QueryAdminPackagedProductsArgs = {
  orderBy?: InputMaybe<AdminPackagedProductOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminPackagedProductsWhereInput>;
};


export type QueryAdminPackagedProductsCountArgs = {
  orderBy?: InputMaybe<AdminPackagedProductOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminPackagedProductsWhereInput>;
};


export type QueryAdminPackagedShowcaseDetailArgs = {
  id: Scalars['Int'];
};


export type QueryAdminPackagedTapColumnDetailArgs = {
  id: Scalars['Int'];
};


export type QueryAdminProductArgs = {
  id: Scalars['Int'];
};


export type QueryAdminProductAccompanimentsArgs = {
  orderBy?: InputMaybe<AdminProductAccompanimentOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminProductAccompanimentsWhereInput>;
};


export type QueryAdminProductAccompanimentsCountArgs = {
  orderBy?: InputMaybe<AdminProductAccompanimentOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminProductAccompanimentsWhereInput>;
};


export type QueryAdminProductsArgs = {
  orderBy?: InputMaybe<AdminProductOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminProductsWhereInput>;
};


export type QueryAdminProductsCountArgs = {
  orderBy?: InputMaybe<AdminProductOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminProductsWhereInput>;
};


export type QueryAdminSlideArgs = {
  id: Scalars['Int'];
};


export type QueryAdminSlidesArgs = {
  orderBy?: InputMaybe<AdminSlideOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminSlidesWhereInput>;
};


export type QueryAdminSlidesCountArgs = {
  orderBy?: InputMaybe<AdminSlideOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminSlidesWhereInput>;
};


export type QueryAdminStaticSlideArgs = {
  id: Scalars['Int'];
};


export type QueryAdminStaticSlideTypeArgs = {
  id: Scalars['Int'];
};


export type QueryAdminStaticSlideTypesArgs = {
  orderBy?: InputMaybe<AdminStaticSlideTypeOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminStaticSlideTypesWhereInput>;
};


export type QueryAdminStaticSlideTypesCountArgs = {
  orderBy?: InputMaybe<AdminStaticSlideTypeOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminStaticSlideTypesWhereInput>;
};


export type QueryAdminStaticSlidesArgs = {
  orderBy?: InputMaybe<AdminStaticSlideOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminStaticSlidesWhereInput>;
};


export type QueryAdminStaticSlidesCountArgs = {
  orderBy?: InputMaybe<AdminStaticSlideOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminStaticSlidesWhereInput>;
};


export type QueryAdminUserArgs = {
  id: Scalars['Int'];
};


export type QueryAdminUsersArgs = {
  orderBy?: InputMaybe<AdminUserOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminUsersWhereInput>;
};


export type QueryAdminUsersCountArgs = {
  orderBy?: InputMaybe<AdminUserOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminUsersWhereInput>;
};


export type QueryBrandsArgs = {
  where?: InputMaybe<BrandWhereInput>;
};


export type QueryCountPointOfSalesArgs = {
  isOwner?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LocationWhereInput>;
};


export type QueryImageArgs = {
  id: Scalars['Int'];
};


export type QueryImagesArgs = {
  ids: Array<Scalars['Int']>;
};


export type QueryLocationsArgs = {
  isOwner?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LocationWhereInput>;
};


export type QueryMediaArgs = {
  id: Scalars['Int'];
};


export type QueryPackagedProductPricesArgs = {
  where?: InputMaybe<PackagedProductPriceWhereInput>;
};


export type QueryPackagedProductsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PackagedProductWhereInput>;
};


export type QueryPointOfSaleArgs = {
  where: PointOfSaleUniqueInput;
};


export type QueryPointOfSaleVisitWholesaleContactArgs = {
  where: PointOfSaleUniqueInput;
};


export type QueryPointOfSaleVisitsArgs = {
  where?: InputMaybe<PointOfSaleVisitWhereInput>;
};


export type QueryPointOfSalesArgs = {
  isOwner?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  userLocation?: InputMaybe<UserLocationInput>;
  where?: InputMaybe<PointOfSaleWhereInput>;
};


export type QueryPointOfSalesSlidesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PointOfSaleSlideWhereInput>;
};


export type QueryProductsArgs = {
  orderBy?: InputMaybe<ProductOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductsWhereInput>;
};


export type QueryProposalArgs = {
  where: WhereProposalInput;
};


export type QueryProposalAssortmentsArgs = {
  where: WhereProposalAssortmentInput;
};


export type QueryProposalCommercialConditionsArgs = {
  where: WhereProposalCommercialConditionInput;
};


export type QueryProposalSlidesArgs = {
  where: WhereProposalSlideInput;
};


export type QueryProposalsArgs = {
  pointOfSaleId?: InputMaybe<IntFilter>;
  skip?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<ProposalStates>;
  take?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<StringFilter>;
};


export type QueryRecommendedAssortmentArgs = {
  pointOfSaleId: Scalars['Int'];
};


export type QuerySlidesArgs = {
  where?: InputMaybe<SlideWhereInput>;
};


export type QueryStaticSlideTypesArgs = {
  orderBy?: InputMaybe<StaticSlideTypeOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StaticSlideTypeWhereInput>;
};


export type QueryUserArgs = {
  auth0Id?: InputMaybe<Scalars['String']>;
  oktaId?: InputMaybe<Scalars['String']>;
};


export type QueryUsersArgs = {
  orderBy?: InputMaybe<AdminUserOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminUsersWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum QueryModeCore {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum RecallType {
  EuroPerLiterLoan = 'EURO_PER_LITER_LOAN',
  EuroPerUnitLoan = 'EURO_PER_UNIT_LOAN',
  LiterRecall = 'LITER_RECALL',
  PercentageLoan = 'PERCENTAGE_LOAN',
  PercentageRecall = 'PERCENTAGE_RECALL',
  Support = 'SUPPORT',
  UnitRecall = 'UNIT_RECALL'
}

export type RecapFields = {
  ads?: InputMaybe<ImportMonthLiters>;
  contributionOther: Scalars['Float'];
  discountOther?: InputMaybe<Scalars['Float']>;
  discountRappel?: InputMaybe<MonthLiters>;
  guarantee?: InputMaybe<GuaranteeInput>;
  loan?: InputMaybe<Loan>;
  rappel?: InputMaybe<ImportMonthLiters>;
};

export type RecommendedPackagedProduct = {
  __typename?: 'RecommendedPackagedProduct';
  category?: Maybe<BeerDetailCategory>;
  packagedProduct: PackagedProduct;
  soldInLastFourMonths: Scalars['Boolean'];
  /** @deprecated Use soldInLastTwoMonth instead */
  soldInLastMonth: Scalars['Boolean'];
  /** @deprecated Use soldInLastFourMonths instead */
  soldInLastThreeMonths: Scalars['Boolean'];
  soldInLastTwoMonth: Scalars['Boolean'];
};

export type Reporting = {
  __typename?: 'Reporting';
  url: Scalars['String'];
};

export enum Role {
  God = 'GOD',
  User = 'USER'
}

export type Sale = {
  __typename?: 'Sale';
  date: Scalars['DateTime'];
  id: Scalars['Int'];
  packagedProduct: PackagedProduct;
  packagedProductId: Scalars['Int'];
  pointOfSaleId: Scalars['Int'];
  unitsNb: Scalars['Int'];
};

export type SaleListRelationFilter = {
  every?: InputMaybe<SaleWhereInput>;
  none?: InputMaybe<SaleWhereInput>;
  some?: InputMaybe<SaleWhereInput>;
};

export type SaleWhereInput = {
  AND?: InputMaybe<Array<SaleWhereInput>>;
  NOT?: InputMaybe<Array<SaleWhereInput>>;
  OR?: InputMaybe<Array<SaleWhereInput>>;
  date?: InputMaybe<DateFilter>;
  id?: InputMaybe<IntFilter>;
  packagedProduct?: InputMaybe<PackagedProductRelationFilter>;
  packagedProductId?: InputMaybe<IntFilter>;
  unitsNb?: InputMaybe<IntFilter>;
};

export enum Segment {
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  C4 = 'C4',
  C5 = 'C5'
}

export type SegmentFilter = {
  in?: InputMaybe<Array<Segment>>;
};

export enum SegmentType {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D'
}

export type SegmentTypeFilter = {
  in?: InputMaybe<Array<SegmentType>>;
};

export type SelectionItem = {
  __typename?: 'SelectionItem';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ShortProposalProductInput = {
  commercialCondition?: InputMaybe<UpsertCommercialConditionInput>;
  format?: InputMaybe<PackagedShowcaseDetailsFormat>;
  productId: Scalars['Int'];
};

export type Slide = {
  __typename?: 'Slide';
  id: Scalars['Int'];
  image: Image;
  product?: Maybe<Product>;
  slideImage: Image;
  slideImageId: Scalars['Int'];
  slidePartChart?: Maybe<SlidePart>;
  slidePartProduct?: Maybe<SlidePart>;
  slideParts: Array<SlidePart>;
  status: SlideStatus;
};

export type SlidePart = {
  __typename?: 'SlidePart';
  backgroundColor: Scalars['String'];
  id: Scalars['ID'];
  image: Image;
  imageId: Scalars['Int'];
  slide: Slide;
  slideId: Scalars['Int'];
  slidePartType: SlidePartType;
};

export type SlidePartCreateManySlideInput = {
  backgroundColor: Scalars['String'];
  imageId: Scalars['Int'];
  slidePartType: SlidePartType;
};

export type SlidePartCreateManySlideInputEnvelope = {
  data: Array<SlidePartCreateManySlideInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SlidePartCreateNestedManyWithoutSlideInput = {
  createMany?: InputMaybe<SlidePartCreateManySlideInputEnvelope>;
};

export enum SlidePartType {
  Accompaniements = 'ACCOMPANIEMENTS',
  PolarChart = 'POLAR_CHART',
  Product = 'PRODUCT',
  StockImage = 'STOCK_IMAGE',
  Text1 = 'TEXT1',
  Text2 = 'TEXT2',
  Text3 = 'TEXT3'
}

export enum SlideStatus {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE'
}

export type SlideStatusFilter = {
  in?: InputMaybe<Array<SlideStatus>>;
};

export type SlideUpdateInput = {
  product?: InputMaybe<ProductCreateNestedOneWithoutSlideInput>;
  slideImageId?: InputMaybe<Scalars['Int']>;
  slideParts?: InputMaybe<SlidePartCreateNestedManyWithoutSlideInput>;
  status?: InputMaybe<SlideStatus>;
};

export type SlideWhereInput = {
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  product?: InputMaybe<ProductsWhereInput>;
};

export type SlideWhereUniqueInput = {
  id: Scalars['Int'];
};

export type SoldProductRelationFilter = {
  every?: InputMaybe<SoldProductWhereInput>;
  none?: InputMaybe<SoldProductWhereInput>;
  some?: InputMaybe<SoldProductWhereInput>;
};

export type SoldProductWhereInput = {
  AND?: InputMaybe<Array<SoldProductWhereInput>>;
  NOT?: InputMaybe<Array<SoldProductWhereInput>>;
  OR?: InputMaybe<Array<SoldProductWhereInput>>;
  container?: InputMaybe<PackagedBeerDetailsContainer>;
  productId?: InputMaybe<Scalars['Int']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum SortOrderCore {
  Asc = 'asc',
  Desc = 'desc'
}

export type StaticSlide = {
  __typename?: 'StaticSlide';
  brandId?: Maybe<Scalars['Int']>;
  enabledByDefault: Scalars['Boolean'];
  id: Scalars['Int'];
  image: Image;
  imageId: Scalars['Int'];
  order: Scalars['Int'];
  type: Scalars['String'];
  typeId: Scalars['Int'];
};

export type StaticSlideTypeNew = {
  __typename?: 'StaticSlideTypeNew';
  editable: Scalars['Boolean'];
  id: Scalars['Int'];
  order: Scalars['Int'];
  packagedProducts?: Maybe<Array<PackagedProduct>>;
  productCategory?: Maybe<ProductCategory>;
  staticSlides: Array<StaticSlide>;
  type: Scalars['String'];
};

export type StaticSlideTypeOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type StaticSlideTypeWhereInput = {
  AND?: InputMaybe<Array<StaticSlideTypeWhereInput>>;
  NOT?: InputMaybe<Array<StaticSlideTypeWhereInput>>;
  OR?: InputMaybe<Array<StaticSlideTypeWhereInput>>;
  editable?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<IntFilter>;
  packagedProducts?: InputMaybe<PackagedProductListRelationFilterInput>;
  type?: InputMaybe<StringFilter>;
};

export type StaticSlideWhereInput = {
  id: IntFilter;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  mode?: InputMaybe<QueryMode>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringFilterCore = {
  contains?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  mode?: InputMaybe<QueryModeCore>;
};

export type SynthesisInput = {
  adsFirstGuaranteePercent?: InputMaybe<Scalars['Float']>;
  adsFirstGuaranteeType?: InputMaybe<GuaranteeType>;
  adsImport?: InputMaybe<Scalars['Float']>;
  adsLiters?: InputMaybe<Scalars['Float']>;
  adsMonth?: InputMaybe<Scalars['Float']>;
  adsSecondGuaranteePercent?: InputMaybe<Scalars['Float']>;
  adsSecondGuaranteeType?: InputMaybe<GuaranteeType>;
  contributionOther?: InputMaybe<Scalars['Float']>;
  discount?: InputMaybe<Scalars['Float']>;
  discountOther?: InputMaybe<Scalars['Float']>;
  discountRappelLiters?: InputMaybe<Scalars['Float']>;
  discountRappelMonth?: InputMaybe<Scalars['Float']>;
  estimatedFreeLiters?: InputMaybe<Scalars['Float']>;
  estimatedLiters?: InputMaybe<Scalars['Float']>;
  firstGuaranteePercent?: InputMaybe<Scalars['Float']>;
  firstGuaranteeType?: InputMaybe<GuaranteeType>;
  loanImport?: InputMaybe<Scalars['Float']>;
  loanLiters?: InputMaybe<Scalars['Float']>;
  loanLitersPerYear?: InputMaybe<Scalars['Float']>;
  loanMonth?: InputMaybe<Scalars['Float']>;
  marketingSupport?: InputMaybe<Scalars['Float']>;
  noChargeProducts?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  plvSupport?: InputMaybe<Scalars['Float']>;
  pointOfSaleSupport?: InputMaybe<Scalars['Float']>;
  rappel?: InputMaybe<Scalars['Float']>;
  rappelImport?: InputMaybe<Scalars['Float']>;
  rappelLiters?: InputMaybe<Scalars['Float']>;
  rappelMonth?: InputMaybe<Scalars['Float']>;
  secondGuaranteePercent?: InputMaybe<Scalars['Float']>;
  secondGuaranteeType?: InputMaybe<GuaranteeType>;
  specialDecoration?: InputMaybe<Scalars['Float']>;
};

export type TagsFilterInput = {
  equals?: InputMaybe<Array<Scalars['String']>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<Scalars['String']>>;
  hasSome?: InputMaybe<Array<Scalars['String']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type Training = {
  __typename?: 'Training';
  contentId: Scalars['Int'];
  id: Scalars['Int'];
  media: Media;
  order: Scalars['Int'];
  title: Scalars['String'];
};

export type TrainingCreateInput = {
  contentId: Scalars['Int'];
  title: Scalars['String'];
};

export type TrainingWhereInput = {
  id: IntFilter;
};

export type TypeAmountInput = {
  amount: Scalars['Float'];
  type?: InputMaybe<GuaranteeType>;
};

export type UpsertCommercialConditionInput = {
  amortizationType?: InputMaybe<RecallType>;
  amortizationValue?: InputMaybe<Scalars['Float']>;
  customPrice?: InputMaybe<Scalars['Float']>;
  discountType?: InputMaybe<DiscountType>;
  discountValue?: InputMaybe<Scalars['Float']>;
  freePackagedProductUnitsNb?: InputMaybe<Scalars['Int']>;
  packagedProductId: Scalars['Int'];
  packagedProductUnitsNb: Scalars['Int'];
  recallType?: InputMaybe<RecallType>;
  recallValue?: InputMaybe<Scalars['Float']>;
  x?: InputMaybe<Scalars['Int']>;
  y?: InputMaybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  kpi: Kpi;
  lastName?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['String']>;
  oktaId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  role: Role;
  userCode: Scalars['String'];
};

export type UserLocationInput = {
  distance: SortOrder;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type UserRoleFilter = {
  in?: InputMaybe<Array<Role>>;
};

export enum VisitMode {
  Phone = 'PHONE',
  Physicaly = 'PHYSICALY'
}

export enum VisitStatus {
  Executed = 'EXECUTED',
  NotRealized = 'NOT_REALIZED',
  Planned = 'PLANNED'
}

export enum VisitTopic {
  Agreements = 'AGREEMENTS',
  Bar = 'BAR',
  CensusUpdate = 'CENSUS_UPDATE',
  Courtesy = 'COURTESY',
  Dcmc = 'DCMC',
  DiscountNegotiation = 'DISCOUNT_NEGOTIATION',
  FinancialChannelInfo = 'FINANCIAL_CHANNEL_INFO',
  LaLiga = 'LA_LIGA',
  OtherIncidences = 'OTHER_INCIDENCES',
  Referencing = 'REFERENCING',
  Rentabilibar = 'RENTABILIBAR',
  RentabilibarOrders = 'RENTABILIBAR_ORDERS',
  StpActivity = 'STP_ACTIVITY',
  VoldisExpress = 'VOLDIS_EXPRESS'
}

export enum VisitType {
  ClientAttracion = 'CLIENT_ATTRACION',
  ClientDefense = 'CLIENT_DEFENSE',
  ClientDevelopment = 'CLIENT_DEVELOPMENT'
}

export type WhereProposalAssortmentInput = {
  proposalId: IntFilter;
};

export type WhereProposalCommercialConditionInput = {
  proposalId: IntFilter;
};

export type WhereProposalInput = {
  id: Scalars['Int'];
};

export type WhereProposalSlideInput = {
  proposalId: IntFilter;
};

export type AccompanimentFragment = { title: string, image: ImageFragment };

export type GetProductAccompanimentQueryVariables = Exact<{
  productId: Scalars['Int'];
}>;


export type GetProductAccompanimentQuery = { products: Array<{ accompaniments: Array<AccompanimentFragment> }> };

export type GetProductsAccompanimentQueryVariables = Exact<{
  productIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type GetProductsAccompanimentQuery = { products: Array<{ id: number, accompaniments: Array<AccompanimentFragment> }> };

export type BrandFragment = { id: number, name: string, isMainBrand: boolean, abbreviation: string, bannerImage?: ImageFragment | null, image?: ImageFragment | null };

export type BrandWithProductsFragment = { id: number, name: string, isMainBrand: boolean, abbreviation: string, products: Array<ProductFragment> };

export type MiniBrandFragment = { id: number, name: string, isMainBrand: boolean, abbreviation: string, image?: ImageFragment | null };

export type GetAllBrandsQueryVariables = Exact<{
  where?: InputMaybe<BrandWhereInput>;
}>;


export type GetAllBrandsQuery = { brands: Array<BrandFragment> };

export type GetAllMiniBrandsQueryVariables = Exact<{
  where?: InputMaybe<BrandWhereInput>;
}>;


export type GetAllMiniBrandsQuery = { brands: Array<MiniBrandFragment> };

export type GetAllBrandsWithProductsQueryVariables = Exact<{
  where?: InputMaybe<BrandWhereInput>;
}>;


export type GetAllBrandsWithProductsQuery = { brands: Array<BrandWithProductsFragment> };

export type SetCommercialConditionsMutationVariables = Exact<{
  proposalId: Scalars['Int'];
  isDirectBilling: Scalars['Boolean'];
  isTankDirectBilling?: InputMaybe<Scalars['Boolean']>;
  generateSlides: Scalars['Boolean'];
  commercialConditions: Array<UpsertCommercialConditionInput> | UpsertCommercialConditionInput;
  resumeFields?: InputMaybe<SynthesisInput>;
  customCommercialCondition?: InputMaybe<Array<CustomCommercialConditionsInput> | CustomCommercialConditionsInput>;
  provinceNumber?: InputMaybe<Scalars['Int']>;
}>;


export type SetCommercialConditionsMutation = { upsertCommercialConditions: Array<ProposalSlideFragment> };

export type ContactFragment = { id: number, firstName?: string | null, lastName?: string | null, phone?: string | null, mobilePhone?: string | null, email?: string | null };

export type ContractFragment = { id: number, startDate: any, endDate: any, payedAmount: number, totalAmount: number, type?: ContractType | null };

export type BeerDetailsFragment = { id: number, description: string, graduation: number, ibu: number, category?: BeerDetailCategory | null };

export type PackagedBeerDetailsFragment = { id: number, packagedProductId: number, volume: number, container: PackagedBeerDetailsContainer, unitsNb?: number | null };

export type PackagedLampDetailsFragment = { id: number, weight?: number | null, consumption?: number | null, dimensions?: string | null };

export type PackagedPosDisplayDetailsFragment = { id: number, units?: number | null, dimensions?: string | null };

export type PackagedShowcaseDetailsFragment = { id: number, weight?: number | null, consumptionPerDay?: number | null, canUnits?: number | null, bottleUnits?: number | null, dimensions?: string | null, format?: PackagedShowcaseDetailsFormat | null, volumeCase?: number | null };

export type PackagedTapColumnDetailsFragment = { id: number, weight?: number | null, height?: number | null, isIlluminated: boolean, dimensions?: string | null, tapBase?: string | null };

export type IdNameFragment = { id: number, name: string };

export type ImageFragment = { id: number, source: MediaFragment };

export type ImageLowestFragment = { id: number, st: MediaFragment };

export type ImageLowFragment = { id: number, nhd: MediaFragment };

export type ImageHighFragment = { id: number, hd: MediaFragment };

export type KpiFragment = { averagePricePerHectoliter: number, averagePricePerHectoliterByManager: number };

export type LocationFragment = { id: number, address: string, latitude?: number | null, longitude?: number | null, pointOfSaleId: number, isBuyer: boolean };

export type GetAllLocationsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  isOwner?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Array<PointOfSaleWhereInput> | PointOfSaleWhereInput>;
  segmentType?: InputMaybe<SegmentTypeFilter>;
  segment?: InputMaybe<SegmentFilter>;
  brandId?: InputMaybe<IntFilter>;
  rentabilibar?: InputMaybe<Scalars['Boolean']>;
  beerTAM?: InputMaybe<FloatFilter>;
  laLiga?: InputMaybe<Scalars['Boolean']>;
  underContract?: InputMaybe<Scalars['Boolean']>;
  soldProducts?: InputMaybe<SoldProductRelationFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  address?: InputMaybe<StringFilter>;
  clientType?: InputMaybe<ClientTypeFilter>;
}>;


export type GetAllLocationsQuery = { locations: Array<LocationFragment> };

export type BookmarkProductsMutationVariables = Exact<{
  data: BookmarkedProductsInput;
}>;


export type BookmarkProductsMutation = { bookmarkProducts?: { id: number } | null };

export type MeFragment = { id: number, externalId: string, kpi: KpiFragment };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { me: UserFragment };

export type MediaFragment = { id: number, name: string, blobUrl: string, type: string };

export type NewFragment = { id: number, title: string, media: MediaFragment, image: ImageFragment };

export type GetAllNewsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllNewsQuery = { news: Array<NewFragment> };

export type GetPackagedProductPriceQueryVariables = Exact<{
  where?: InputMaybe<PackagedProductPriceWhereInput>;
}>;


export type GetPackagedProductPriceQuery = { packagedProductPrices: Array<{ packagedProductId: number, price: number }> };

export type AssortmentPointOfSaleFragment = { id: number, ownerExternalId: string, provinceNumber: number, sales: Array<SaleFragment>, brand?: BrandFragment | null };

export type PointOfSaleMiniFragment = { id: number, name: string, taxId: string, segmentType?: SegmentType | null, segment?: Segment | null, underContract: boolean, lastVisitDate?: any | null, averagePricePerHectoliter?: number | null, isActive: boolean, externalId: string, ownerExternalId: string, brand?: BrandFragment | null, location?: LocationFragment | null, sales: Array<SaleMiniFragment> };

export type PointOfSaleFragment = (
  { beerLiterAnnualSalesPotential?: number | null, rentabilibar: boolean, beerTAM: number, waterTAM: number, laLiga?: boolean | null, phone?: string | null, taxId: string, contracts: Array<ContractFragment>, sales: Array<SaleFragment> }
  & PointOfSaleMiniFragment
);

export type PointOfSaleMapFragment = { id: number, name: string, segmentType?: SegmentType | null, segment?: Segment | null, underContract: boolean, isActive: boolean, ownerExternalId: string, brand?: BrandFragment | null, location?: LocationFragment | null, sales: Array<{ id: number }> };

export type PointOfSaleOverviewFragment = { id: number, segment?: Segment | null, laLiga?: boolean | null, rentabilibar: boolean, underContract: boolean, beerLiterAnnualSalesPotential?: number | null, lastVisitDate?: any | null, externalId: string, companyName: string, typeName: string, taxId: string, ownerName: string, phone?: string | null, email: string, ownerExternalId: string, competitionVolume?: number | null, location?: LocationFragment | null, contracts: Array<ContractFragment>, brand?: MiniBrandFragment | null, contact?: ContactFragment | null };

export type PointOfSaleMicroFragment = { id: number, isActive: boolean, name: string, externalId: string, ownerExternalId: string, brand?: BrandFragment | null };

export type PointOfSaleVolumeFragment = { id: number, name: string, ownerExternalId: string, sales: Array<SaleVolumeFragment> };

export type PointOfSaleKpiFragment = { id: number, ownerExternalId: string, averagePricePerHectoliter?: number | null, ownerAveragePriceByHectoliters: number, contracts: Array<ContractFragment> };

export type ImportPointOfSaleMutationVariables = Exact<{
  externalId: Scalars['String'];
}>;


export type ImportPointOfSaleMutation = { importPointOfSaleFromSherpa: PointOfSaleMiniFragment };

export type UpdateSherpaPointOfSaleMutationVariables = Exact<{
  externalId: Scalars['String'];
  data: PointOfSaleUpdateInput;
}>;


export type UpdateSherpaPointOfSaleMutation = { updateSherpaPointOfSale: { externalId: string } };

export type CreateSherpaPointOfSaleMutationVariables = Exact<{
  data: PointOfSaleCreateInput;
}>;


export type CreateSherpaPointOfSaleMutation = { createSherpaPointOfSale: PointOfSaleMiniFragment };

export type GetAllPosQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetAllPosQuery = { pointOfSales: Array<PointOfSaleMiniFragment> };

export type GetPosQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPosQuery = { pointOfSales: Array<PointOfSaleFragment> };

export type GetAssortmentPointOfSaleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetAssortmentPointOfSaleQuery = { pointOfSales: Array<AssortmentPointOfSaleFragment> };

export type GetSingleMapPosQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSingleMapPosQuery = { pointOfSales: Array<PointOfSaleMapFragment> };

export type GetPosOverviewQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPosOverviewQuery = { pointOfSale: PointOfSaleOverviewFragment };

export type GetPosMicroQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPosMicroQuery = { pointOfSale: PointOfSaleMicroFragment };

export type GetPosVolumeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPosVolumeQuery = { pointOfSale: PointOfSaleVolumeFragment };

export type GetPosKpiQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPosKpiQuery = { pointOfSale: PointOfSaleKpiFragment };

export type GetFilteredPointOfSalesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  isOwner?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Array<PointOfSaleWhereInput> | PointOfSaleWhereInput>;
  segmentType?: InputMaybe<SegmentTypeFilter>;
  segment?: InputMaybe<SegmentFilter>;
  brandId?: InputMaybe<IntFilter>;
  rentabilibar?: InputMaybe<Scalars['Boolean']>;
  beerTAM?: InputMaybe<FloatFilter>;
  laLiga?: InputMaybe<Scalars['Boolean']>;
  underContract?: InputMaybe<Scalars['Boolean']>;
  soldProducts?: InputMaybe<SoldProductRelationFilter>;
  userLocation?: InputMaybe<UserLocationInput>;
  location?: InputMaybe<LocationWhereInput>;
  clientType?: InputMaybe<ClientTypeFilter>;
  lastVisitDate?: InputMaybe<DateFilter>;
}>;


export type GetFilteredPointOfSalesQuery = { pointOfSales: Array<PointOfSaleMiniFragment> };

export type GetPointOfSalesQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPointOfSalesQuery = { pointOfSales: Array<PointOfSaleMiniFragment> };

export type GetPointOfSalesTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPointOfSalesTypesQuery = { pointOfSaleType: Array<{ value: string, label: string }> };

export type GetPointOfSalesClassificationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPointOfSalesClassificationQuery = { pointOfSaleClassification: Array<{ value: string, label: string }> };

export type PointOfSaleSlideFragment = { id: number, pointOfSaleId: number, type: PointOfSaleSlideType, slideId: number, order: number, slide: ImageFragment };

export type PointOfSaleSlideNewFragment = { slideId: number, order: number, slide: ImageFragment };

export type ProposalSlideFragment = { id: number, proposalId: number, imageId: number, type: ProposalSlideType, image?: ImageFragment | null };

export type CreatePointOfSaleSlidesMutationVariables = Exact<{
  pointOfSaleId: Scalars['Int'];
  type?: InputMaybe<Array<PointOfSaleSlideType> | PointOfSaleSlideType>;
}>;


export type CreatePointOfSaleSlidesMutation = { createPointOfSaleSlides: Array<PointOfSaleSlideFragment> };

export type CreateNotBeerSlidesMutationVariables = Exact<{
  proposalId: Scalars['Int'];
}>;


export type CreateNotBeerSlidesMutation = { createNotBeerSlides: Array<PointOfSaleSlideFragment> };

export type CreateNotBeerSlidesNewMutationVariables = Exact<{
  proposalId: Scalars['Int'];
}>;


export type CreateNotBeerSlidesNewMutation = { createNotBeerSlidesNew: Array<PointOfSaleSlideNewFragment> };

export type UpdateProposalSlidesMutationVariables = Exact<{
  proposalId: Scalars['Int'];
  slides: Array<ProposalSlideInput> | ProposalSlideInput;
}>;


export type UpdateProposalSlidesMutation = { updateProposalSlides: Array<{ id: number }> };

export type AddProposalProductsMutationVariables = Exact<{
  proposalId: Scalars['Int'];
  brandId: Scalars['Int'];
  products: Array<ProductIdAndType> | ProductIdAndType;
}>;


export type AddProposalProductsMutation = { addProposalProducts?: Array<ProposalSlideFragment> | null };

export type UpdateProposalProductsMutationVariables = Exact<{
  kegBaseId: Scalars['Int'];
  proposalId: Scalars['Int'];
  products: Array<ProductIdAndType> | ProductIdAndType;
}>;


export type UpdateProposalProductsMutation = { updateProposalProducts: Array<{ id: number }> };

export type PointOfSaleVisitFragment = { externalId: string, name: string, status: VisitStatus, mode: VisitMode, type: VisitType, topic: Array<VisitTopic>, startDate?: any | null, endDate?: any | null, description?: string | null, companion: boolean, wholesaleContactExternalId?: string | null, contactUserExternalId?: string | null };

export type CreatePointOfSaleVisitMutationVariables = Exact<{
  pointOfSaleId: Scalars['Int'];
  data: PointOfSaleVisitInput;
}>;


export type CreatePointOfSaleVisitMutation = { createPointOfSaleVisit: string };

export type UpdatePointOfSaleVisitMutationVariables = Exact<{
  visitExternalId: Scalars['String'];
  data: PointOfSaleVisitInput;
}>;


export type UpdatePointOfSaleVisitMutation = { updatePointOfSaleVisit: string };

export type PointOfSaleVisitsQueryVariables = Exact<{
  externalId: Scalars['String'];
  startDate?: InputMaybe<DateFilter>;
}>;


export type PointOfSaleVisitsQuery = { pointOfSaleVisits: Array<PointOfSaleVisitFragment> };

export type PointOfSaleVisitWholesaleContactQueryVariables = Exact<{
  posId: Scalars['Int'];
}>;


export type PointOfSaleVisitWholesaleContactQuery = { pointOfSaleVisitWholesaleContact: Array<{ label: string, externalId: string }> };

export type PackagedProductWithoutProductFragment = { id: number, productId: number, externalId?: string | null, name: string, imageId?: number | null, isMain: boolean, isActive: boolean, image?: ImageFragment | null, slide?: SlideFragment | null, croppedImage?: ImageFragment | null, packagedDetails: PackagedBeerDetailsFragment | PackagedLampDetailsFragment | PackagedPosDisplayDetailsFragment | PackagedShowcaseDetailsFragment | PackagedTapColumnDetailsFragment };

export type PackagedProductWithoutProductWithPriceFragment = { id: number, productId: number, externalId?: string | null, name: string, imageId?: number | null, isMain: boolean, isActive: boolean, packagedProductPrice?: { price: number, provinceNumber?: number | null, directBilling?: number | null, greenTax?: number | null, taxes?: number | null } | null, image?: ImageFragment | null, slide?: SlideFragment | null, croppedImage?: ImageFragment | null, packagedDetails: PackagedBeerDetailsFragment | PackagedLampDetailsFragment | PackagedPosDisplayDetailsFragment | PackagedShowcaseDetailsFragment | PackagedTapColumnDetailsFragment };

export type PackagedProductFragment = (
  { product: ProductWithoutPackagedFragment }
  & PackagedProductWithoutProductFragment
);

export type PackagedProductBeerFragment = { id: number, name: string, productId: number, isMain: boolean, isActive: boolean, product: ProductWithoutPackagedFragment, packagedDetails: PackagedBeerDetailsFragment | {} };

export type PackagedProductBeerWithImageFragment = { id: number, name: string, productId: number, imageId?: number | null, isMain: boolean, isActive: boolean, image?: ImageFragment | null, slide?: SlideFragment | null, croppedImage?: ImageFragment | null, packagedDetails: PackagedBeerDetailsFragment | {} };

export type ProductFragment = { id: number, name: string, shortCode?: string | null, productCode?: string | null, tags: Array<string>, category: ProductCategory, isBookmarked: boolean, isPriceCustomizable: boolean, augmentedRealityUrl?: string | null, brand: MiniBrandFragment, slide?: SlideFragment | null, image?: ImageFragment | null, packagedProducts: Array<PackagedProductWithoutProductFragment>, details?: BeerDetailsFragment | null };

export type ProductWithPricesFragment = { id: number, name: string, shortCode?: string | null, productCode?: string | null, tags: Array<string>, category: ProductCategory, isPriceCustomizable: boolean, brand: MiniBrandFragment, slide?: MiniSlideFragment | null, image?: ImageFragment | null, packagedProducts: Array<PackagedProductWithoutProductWithPriceFragment>, details?: BeerDetailsFragment | null };

export type PriceFragmentFragment = { packagedProducts: Array<{ packagedProductPrice?: { price: number, provinceNumber?: number | null, directBilling?: number | null, greenTax?: number | null, taxes?: number | null } | null }> };

export type ProductWithoutPackagedFragment = { id: number, name: string, shortCode?: string | null, productCode?: string | null, tags: Array<string>, category: ProductCategory, augmentedRealityUrl?: string | null, isPriceCustomizable: boolean, brand: MiniBrandFragment, slide?: SlideFragment | null, image?: ImageFragment | null, details?: BeerDetailsFragment | null };

export type ProductWithoutSlideFragment = { id: number, name: string, isPriceCustomizable: boolean, brand: MiniBrandFragment, packagedProducts: Array<PackagedProductWithoutProductFragment> };

export type MiniProductWithCategoryFragment = { id: number, name: string, category: ProductCategory, isPriceCustomizable: boolean, packagedProducts: Array<PackagedProductWithoutProductFragment> };

export type GetProductsQueryVariables = Exact<{
  where?: InputMaybe<ProductsWhereInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type GetProductsQuery = { products: Array<ProductFragment> };

export type GetProductsWithPricesQueryVariables = Exact<{
  where?: InputMaybe<ProductsWhereInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  pointOfSaleId?: InputMaybe<Scalars['Float']>;
  provinceNumber?: InputMaybe<Scalars['Int']>;
}>;


export type GetProductsWithPricesQuery = { products: Array<ProductWithPricesFragment> };

export type GetProductQueryVariables = Exact<{
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type GetProductQuery = { products: Array<ProductFragment> };

export type GetProductWithPriceQueryVariables = Exact<{
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  pointOfSaleId?: InputMaybe<Scalars['Float']>;
  provinceNumber?: InputMaybe<Scalars['Int']>;
}>;


export type GetProductWithPriceQuery = { products: Array<ProductWithPricesFragment> };

export type GetProductsWithPriceQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  pointOfSaleId?: InputMaybe<Scalars['Float']>;
  provinceNumber?: InputMaybe<Scalars['Int']>;
}>;


export type GetProductsWithPriceQuery = { products: Array<ProductWithPricesFragment> };

export type GetMiniProductsWithCategoryQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type GetMiniProductsWithCategoryQuery = { products: Array<MiniProductWithCategoryFragment> };

export type GetProductsWithoutSlidesQueryVariables = Exact<{
  where?: InputMaybe<ProductsWhereInput>;
}>;


export type GetProductsWithoutSlidesQuery = { products: Array<ProductWithoutSlideFragment> };

export type ProposalFragment = { id: number, title: string, format: ProposalFormat, createdAt: any, updatedAt: any, expireAt?: any | null, brandId?: number | null, state: ProposalStates, user?: { firstName?: string | null, lastName?: string | null, profilePicture?: string | null } | null, pdf?: MediaFragment | null, proposalSlides?: Array<{ type: ProposalSlideType, image?: { hd: { blobUrl: string } } | null }> | null, firstSlide?: { image: { hd: { blobUrl: string } } } | null };

export type ShortProposalFragment = { id: number, pointOfSaleId?: number | null, createdAt: any, updatedAt: any, uuid: string, state: ProposalStates, pdf?: MediaFragment | null };

export type ProposalInfoFragment = { id: number, title: string, brandId?: number | null, pointOfSaleId?: number | null, expireAt?: any | null, commercialConditions?: Array<{ packagedProductId: number, discountValue?: number | null, discountType?: DiscountType | null, recallValue?: number | null, recallType?: RecallType | null, amortizationValue?: number | null, amortizationType?: RecallType | null, packagedProductPrice: number, packagedProductUnitsNb: number, packagedProduct?: { productId: number } | null }> | null, proposalAssortments?: Array<{ productId: number, container?: PackagedBeerDetailsContainer | null }> | null, customCommercialCondition?: Array<{ proposalId: number, name: string, price: number, unitsNb: number }> | null, synthesis?: { id: number, proposalId: number, discountOther?: number | null, adsImport?: number | null, adsMonth?: number | null, adsLiters?: number | null, contributionOther?: number | null, loanImport?: number | null, loanMonth?: number | null, loanLiters?: number | null, loanLitersPerYear?: number | null, rappelImport?: number | null, rappelMonth?: number | null, rappelLiters?: number | null, discountRappelMonth?: number | null, discountRappelLiters?: number | null, firstGuaranteeType?: GuaranteeType | null, firstGuaranteePercent?: number | null, secondGuaranteeType?: GuaranteeType | null, secondGuaranteePercent?: number | null, adsFirstGuaranteeType?: GuaranteeType | null, adsFirstGuaranteePercent?: number | null, adsSecondGuaranteeType?: GuaranteeType | null, adsSecondGuaranteePercent?: number | null, rappel?: number | null, discount?: number | null, noChargeProducts?: number | null, marketingSupport?: number | null, pointOfSaleSupport?: number | null, plvSupport?: number | null, specialDecoration?: number | null, estimatedLiters?: number | null, notes?: string | null } | null, proposalSlides?: Array<{ id: number, imageId: number, type: ProposalSlideType, order: number, proposalId: number, image?: ImageFragment | null }> | null };

export type GetAllProposalsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllProposalsQuery = { proposals: Array<ProposalFragment> };

export type GetMeProposalsQueryVariables = Exact<{
  state?: InputMaybe<ProposalStates>;
}>;


export type GetMeProposalsQuery = { proposals: Array<ProposalFragment> };

export type GetPosProposalsQueryVariables = Exact<{
  posIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type GetPosProposalsQuery = { proposals: Array<ProposalFragment> };

export type GetPosProposalsLastQueryVariables = Exact<{
  posIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type GetPosProposalsLastQuery = { proposals: Array<ProposalFragment> };

export type GetProposalInformationQueryVariables = Exact<{
  proposalId: Scalars['Int'];
}>;


export type GetProposalInformationQuery = { proposal: ProposalInfoFragment };

export type CreateProposalMutationVariables = Exact<{
  title: Scalars['String'];
  pointOfSaleId?: InputMaybe<Scalars['Int']>;
  displayedTitle?: InputMaybe<Scalars['String']>;
  expireAt: Scalars['DateTime'];
}>;


export type CreateProposalMutation = { createProposal: { id: number, pointOfSaleId?: number | null, createdAt: any, updatedAt: any, state: ProposalStates } };

export type CreateProposalMediasMutationVariables = Exact<{
  proposalId: Scalars['Int'];
}>;


export type CreateProposalMediasMutation = { generateProposalPdf: { pdf?: MediaFragment | null, pptx?: MediaFragment | null } };

export type CreateShortProposalMutationVariables = Exact<{
  pointOfSaleId?: InputMaybe<Scalars['Int']>;
  provinceNumber?: InputMaybe<Scalars['Int']>;
  displayedTitle?: InputMaybe<Scalars['String']>;
  products: Array<ShortProposalProductInput> | ShortProposalProductInput;
  isDirectBilling: Scalars['Boolean'];
  isTankDirectBilling?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateShortProposalMutation = { createShortProposal: ShortProposalFragment };

export type GetRecommendedAssortmentQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetRecommendedAssortmentQuery = { recommendedAssortment: Array<RecommendedPackagedProductFragment> };

export type RecommendedPackagedProductFragment = { soldInLastThreeMonths: boolean, soldInLastMonth: boolean, category?: BeerDetailCategory | null, packagedProduct: PackagedProductBeerWithImageFragment };

export type ReportingFragment = { url: string };

export type GetReportingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReportingQuery = { reporting: ReportingFragment };

export type SaleMiniFragment = { packagedProductId: number, unitsNb: number, date: any };

export type SaleFragment = (
  { packagedProduct: PackagedProductFragment }
  & SaleMiniFragment
);

export type SaleVolumeFragment = (
  { packagedProduct: PackagedProductBeerFragment }
  & SaleMiniFragment
);

export type SlideFragment = { id: number, slideImageId: number, status: SlideStatus, image: (
    ImageFragment
    & ImageLowFragment
  ), slideParts: Array<{ id: string, slidePartType: SlidePartType, backgroundColor: string, slideId: number, image: (
      ImageFragment
      & ImageLowFragment
    ) }> };

export type MiniSlideFragment = { id: number, slideImageId: number, image: ImageLowFragment };

export type GetAllSlidesQueryVariables = Exact<{
  where?: InputMaybe<SlideWhereInput>;
}>;


export type GetAllSlidesQuery = { slides: Array<SlideFragment> };

export type GetStaticSlidesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStaticSlidesQuery = { staticSlides: Array<{ id: number, type: string, order: number, imageId: number, image: ImageFragment }> };

export type GetStaticSlideTypesQueryVariables = Exact<{
  where?: InputMaybe<StaticSlideTypeWhereInput>;
  orderBy?: InputMaybe<StaticSlideTypeOrderByWithRelationInput>;
}>;


export type GetStaticSlideTypesQuery = { staticSlideTypes: Array<{ id: number, type: string, order: number, editable: boolean, productCategory?: ProductCategory | null, staticSlides: Array<{ id: number, type: string, order: number, enabledByDefault: boolean, brandId?: number | null, typeId: number, image: ImageFragment }> }> };

export type TrainingFragment = { id: number, contentId: number, order: number, title: string, media: MediaFragment };

export type GetAllTrainingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTrainingQuery = { trainings: Array<TrainingFragment> };

export type GetAllUsersQueryVariables = Exact<{
  where?: InputMaybe<AdminUsersWhereInput>;
}>;


export type GetAllUsersQuery = { users: Array<UserFragment> };

export type UserFragment = { id: number, firstName?: string | null, lastName?: string | null, role: Role, email: string, phoneNumber?: string | null, profilePicture?: string | null, externalId: string };

export const MediaFragmentDoc = gql`
    fragment media on Media {
  id
  name
  blobUrl
  type
}
    `;
export const ImageFragmentDoc = gql`
    fragment image on Image {
  id
  source {
    ...media
  }
}
    ${MediaFragmentDoc}`;
export const AccompanimentFragmentDoc = gql`
    fragment accompaniment on Accompaniment {
  title
  image {
    ...image
  }
}
    ${ImageFragmentDoc}`;
export const MiniBrandFragmentDoc = gql`
    fragment miniBrand on Brand {
  id
  name
  isMainBrand
  abbreviation
  image {
    ...image
  }
}
    ${ImageFragmentDoc}`;
export const ImageLowFragmentDoc = gql`
    fragment imageLow on Image {
  id
  nhd {
    ...media
  }
}
    ${MediaFragmentDoc}`;
export const SlideFragmentDoc = gql`
    fragment slide on Slide {
  id
  slideImageId
  image {
    ...image
    ...imageLow
  }
  status
  slideParts {
    id
    slidePartType
    image {
      ...image
      ...imageLow
    }
    backgroundColor
    slideId
  }
}
    ${ImageFragmentDoc}
${ImageLowFragmentDoc}`;
export const PackagedBeerDetailsFragmentDoc = gql`
    fragment packagedBeerDetails on PackagedBeerDetails {
  id
  packagedProductId
  volume
  container
  unitsNb
}
    `;
export const PackagedShowcaseDetailsFragmentDoc = gql`
    fragment packagedShowcaseDetails on PackagedShowcaseDetails {
  id
  weight
  consumptionPerDay
  volumeCase: volume
  canUnits
  bottleUnits
  dimensions
  format
}
    `;
export const PackagedLampDetailsFragmentDoc = gql`
    fragment packagedLampDetails on PackagedLampDetails {
  id
  weight
  consumption
  dimensions
}
    `;
export const PackagedPosDisplayDetailsFragmentDoc = gql`
    fragment packagedPosDisplayDetails on PackagedPosDisplayDetails {
  id
  units
  dimensions
}
    `;
export const PackagedTapColumnDetailsFragmentDoc = gql`
    fragment packagedTapColumnDetails on PackagedTapColumnDetails {
  id
  weight
  height
  isIlluminated
  dimensions
  tapBase
}
    `;
export const PackagedProductWithoutProductFragmentDoc = gql`
    fragment packagedProductWithoutProduct on PackagedProduct {
  id
  productId
  externalId
  name
  imageId
  isMain
  isActive
  image {
    ...image
  }
  slide {
    ...slide
  }
  croppedImage {
    ...image
  }
  packagedDetails {
    ... on PackagedBeerDetails {
      ...packagedBeerDetails
    }
    ... on PackagedShowcaseDetails {
      ...packagedShowcaseDetails
    }
    ... on PackagedLampDetails {
      ...packagedLampDetails
    }
    ... on PackagedPosDisplayDetails {
      ...packagedPosDisplayDetails
    }
    ... on PackagedTapColumnDetails {
      ...packagedTapColumnDetails
    }
  }
}
    ${ImageFragmentDoc}
${SlideFragmentDoc}
${PackagedBeerDetailsFragmentDoc}
${PackagedShowcaseDetailsFragmentDoc}
${PackagedLampDetailsFragmentDoc}
${PackagedPosDisplayDetailsFragmentDoc}
${PackagedTapColumnDetailsFragmentDoc}`;
export const BeerDetailsFragmentDoc = gql`
    fragment beerDetails on BeerDetails {
  id
  description
  graduation
  ibu
  category
}
    `;
export const ProductFragmentDoc = gql`
    fragment product on Product {
  id
  name
  shortCode
  productCode
  tags
  category
  isBookmarked
  isPriceCustomizable
  augmentedRealityUrl
  brand {
    ...miniBrand
  }
  slide {
    ...slide
  }
  image {
    ...image
  }
  packagedProducts {
    ...packagedProductWithoutProduct
  }
  details {
    ... on BeerDetails {
      ...beerDetails
    }
  }
}
    ${MiniBrandFragmentDoc}
${SlideFragmentDoc}
${ImageFragmentDoc}
${PackagedProductWithoutProductFragmentDoc}
${BeerDetailsFragmentDoc}`;
export const BrandWithProductsFragmentDoc = gql`
    fragment brandWithProducts on Brand {
  id
  name
  isMainBrand
  abbreviation
  products {
    ...product
  }
}
    ${ProductFragmentDoc}`;
export const IdNameFragmentDoc = gql`
    fragment idName on IdName {
  id
  name
}
    `;
export const ImageLowestFragmentDoc = gql`
    fragment imageLowest on Image {
  id
  st {
    ...media
  }
}
    ${MediaFragmentDoc}`;
export const ImageHighFragmentDoc = gql`
    fragment imageHigh on Image {
  id
  hd {
    ...media
  }
}
    ${MediaFragmentDoc}`;
export const KpiFragmentDoc = gql`
    fragment kpi on KPI {
  averagePricePerHectoliter
  averagePricePerHectoliterByManager
}
    `;
export const MeFragmentDoc = gql`
    fragment me on User {
  id
  externalId: userCode
  kpi {
    ...kpi
  }
}
    ${KpiFragmentDoc}`;
export const NewFragmentDoc = gql`
    fragment new on News {
  id
  title
  media {
    ...media
  }
  image {
    ...image
  }
}
    ${MediaFragmentDoc}
${ImageFragmentDoc}`;
export const SaleMiniFragmentDoc = gql`
    fragment saleMini on Sale {
  packagedProductId
  unitsNb
  date
}
    `;
export const ProductWithoutPackagedFragmentDoc = gql`
    fragment productWithoutPackaged on Product {
  id
  name
  shortCode
  productCode
  tags
  category
  augmentedRealityUrl
  isPriceCustomizable
  brand {
    ...miniBrand
  }
  slide {
    ...slide
  }
  image {
    ...image
  }
  details {
    ... on BeerDetails {
      ...beerDetails
    }
  }
}
    ${MiniBrandFragmentDoc}
${SlideFragmentDoc}
${ImageFragmentDoc}
${BeerDetailsFragmentDoc}`;
export const PackagedProductFragmentDoc = gql`
    fragment packagedProduct on PackagedProduct {
  ...packagedProductWithoutProduct
  product {
    ...productWithoutPackaged
  }
}
    ${PackagedProductWithoutProductFragmentDoc}
${ProductWithoutPackagedFragmentDoc}`;
export const SaleFragmentDoc = gql`
    fragment sale on Sale {
  ...saleMini
  packagedProduct {
    ...packagedProduct
  }
}
    ${SaleMiniFragmentDoc}
${PackagedProductFragmentDoc}`;
export const BrandFragmentDoc = gql`
    fragment brand on Brand {
  id
  name
  isMainBrand
  abbreviation
  bannerImage {
    ...image
  }
  image {
    ...image
  }
}
    ${ImageFragmentDoc}`;
export const AssortmentPointOfSaleFragmentDoc = gql`
    fragment assortmentPointOfSale on PointOfSale {
  id
  sales {
    ...sale
  }
  brand {
    ...brand
  }
  ownerExternalId
  provinceNumber
}
    ${SaleFragmentDoc}
${BrandFragmentDoc}`;
export const LocationFragmentDoc = gql`
    fragment location on Location {
  id
  address
  latitude
  longitude
  pointOfSaleId
  isBuyer
}
    `;
export const PointOfSaleMiniFragmentDoc = gql`
    fragment pointOfSaleMini on PointOfSale {
  id
  name
  brand {
    ...brand
  }
  location {
    ...location
  }
  sales {
    ...saleMini
  }
  taxId
  segmentType
  segment
  underContract
  lastVisitDate
  averagePricePerHectoliter
  isActive
  externalId
  ownerExternalId
}
    ${BrandFragmentDoc}
${LocationFragmentDoc}
${SaleMiniFragmentDoc}`;
export const ContractFragmentDoc = gql`
    fragment contract on Contract {
  id
  startDate
  endDate
  payedAmount
  totalAmount
  type
}
    `;
export const PointOfSaleFragmentDoc = gql`
    fragment pointOfSale on PointOfSale {
  ...pointOfSaleMini
  beerLiterAnnualSalesPotential
  rentabilibar
  beerTAM
  waterTAM
  laLiga
  phone
  taxId
  contracts {
    ...contract
  }
  sales {
    ...sale
  }
}
    ${PointOfSaleMiniFragmentDoc}
${ContractFragmentDoc}
${SaleFragmentDoc}`;
export const PointOfSaleMapFragmentDoc = gql`
    fragment pointOfSaleMap on PointOfSale {
  id
  name
  brand {
    ...brand
  }
  location {
    ...location
  }
  segmentType
  segment
  underContract
  isActive
  sales {
    id
  }
  ownerExternalId
}
    ${BrandFragmentDoc}
${LocationFragmentDoc}`;
export const ContactFragmentDoc = gql`
    fragment contact on Contact {
  id
  firstName
  lastName
  phone
  mobilePhone
  email
}
    `;
export const PointOfSaleOverviewFragmentDoc = gql`
    fragment pointOfSaleOverview on PointOfSale {
  id
  location {
    ...location
  }
  contracts {
    ...contract
  }
  brand {
    ...miniBrand
  }
  contact {
    ...contact
  }
  segment
  laLiga
  rentabilibar
  underContract
  beerLiterAnnualSalesPotential
  lastVisitDate
  externalId
  companyName
  typeName
  taxId
  ownerName
  phone
  email
  ownerExternalId
  competitionVolume
}
    ${LocationFragmentDoc}
${ContractFragmentDoc}
${MiniBrandFragmentDoc}
${ContactFragmentDoc}`;
export const PointOfSaleMicroFragmentDoc = gql`
    fragment pointOfSaleMicro on PointOfSale {
  id
  isActive
  name
  externalId
  ownerExternalId
  brand {
    ...brand
  }
}
    ${BrandFragmentDoc}`;
export const PackagedProductBeerFragmentDoc = gql`
    fragment packagedProductBeer on PackagedProduct {
  id
  name
  productId
  isMain
  isActive
  product {
    ...productWithoutPackaged
  }
  packagedDetails {
    ... on PackagedBeerDetails {
      ...packagedBeerDetails
    }
  }
}
    ${ProductWithoutPackagedFragmentDoc}
${PackagedBeerDetailsFragmentDoc}`;
export const SaleVolumeFragmentDoc = gql`
    fragment saleVolume on Sale {
  ...saleMini
  packagedProduct {
    ...packagedProductBeer
  }
}
    ${SaleMiniFragmentDoc}
${PackagedProductBeerFragmentDoc}`;
export const PointOfSaleVolumeFragmentDoc = gql`
    fragment pointOfSaleVolume on PointOfSale {
  id
  name
  ownerExternalId
  sales {
    ...saleVolume
  }
}
    ${SaleVolumeFragmentDoc}`;
export const PointOfSaleKpiFragmentDoc = gql`
    fragment pointOfSaleKPI on PointOfSale {
  id
  ownerExternalId
  contracts {
    ...contract
  }
  averagePricePerHectoliter
  ownerAveragePriceByHectoliters
}
    ${ContractFragmentDoc}`;
export const PointOfSaleSlideFragmentDoc = gql`
    fragment pointOfSaleSlide on PointOfSaleSlide {
  id
  pointOfSaleId
  type
  slideId
  order
  slide {
    ...image
  }
}
    ${ImageFragmentDoc}`;
export const PointOfSaleSlideNewFragmentDoc = gql`
    fragment pointOfSaleSlideNew on DynamicSlide {
  slideId
  order
  slide {
    ...image
  }
}
    ${ImageFragmentDoc}`;
export const ProposalSlideFragmentDoc = gql`
    fragment proposalSlide on ProposalSlide {
  id
  proposalId
  imageId
  type
  image {
    ...image
  }
}
    ${ImageFragmentDoc}`;
export const PointOfSaleVisitFragmentDoc = gql`
    fragment pointOfSaleVisit on PointOfSaleVisit {
  externalId
  name
  status
  mode
  type
  topic
  startDate
  endDate
  description
  companion
  wholesaleContactExternalId
  contactUserExternalId
}
    `;
export const MiniSlideFragmentDoc = gql`
    fragment miniSlide on Slide {
  id
  slideImageId
  image {
    ...imageLow
  }
}
    ${ImageLowFragmentDoc}`;
export const PackagedProductWithoutProductWithPriceFragmentDoc = gql`
    fragment packagedProductWithoutProductWithPrice on PackagedProduct {
  id
  productId
  externalId
  name
  imageId
  packagedProductPrice(
    pointOfSaleId: $pointOfSaleId
    provinceNumber: $provinceNumber
  ) {
    price
    provinceNumber
    directBilling
    greenTax
    taxes
  }
  isMain
  isActive
  image {
    ...image
  }
  slide {
    ...slide
  }
  croppedImage {
    ...image
  }
  packagedDetails {
    ... on PackagedBeerDetails {
      ...packagedBeerDetails
    }
    ... on PackagedShowcaseDetails {
      ...packagedShowcaseDetails
    }
    ... on PackagedLampDetails {
      ...packagedLampDetails
    }
    ... on PackagedPosDisplayDetails {
      ...packagedPosDisplayDetails
    }
    ... on PackagedTapColumnDetails {
      ...packagedTapColumnDetails
    }
  }
}
    ${ImageFragmentDoc}
${SlideFragmentDoc}
${PackagedBeerDetailsFragmentDoc}
${PackagedShowcaseDetailsFragmentDoc}
${PackagedLampDetailsFragmentDoc}
${PackagedPosDisplayDetailsFragmentDoc}
${PackagedTapColumnDetailsFragmentDoc}`;
export const ProductWithPricesFragmentDoc = gql`
    fragment productWithPrices on Product {
  id
  name
  shortCode
  productCode
  tags
  category
  isPriceCustomizable
  brand {
    ...miniBrand
  }
  slide {
    ...miniSlide
  }
  image {
    ...image
  }
  packagedProducts {
    ...packagedProductWithoutProductWithPrice
  }
  details {
    ... on BeerDetails {
      ...beerDetails
    }
  }
  shortCode
}
    ${MiniBrandFragmentDoc}
${MiniSlideFragmentDoc}
${ImageFragmentDoc}
${PackagedProductWithoutProductWithPriceFragmentDoc}
${BeerDetailsFragmentDoc}`;
export const PriceFragmentFragmentDoc = gql`
    fragment priceFragment on Product {
  packagedProducts {
    packagedProductPrice(
      pointOfSaleId: $pointOfSaleId
      provinceNumber: $provinceNumber
    ) {
      price
      provinceNumber
      directBilling
      greenTax
      taxes
    }
  }
}
    `;
export const ProductWithoutSlideFragmentDoc = gql`
    fragment productWithoutSlide on Product {
  id
  name
  isPriceCustomizable
  brand {
    ...miniBrand
  }
  packagedProducts {
    ...packagedProductWithoutProduct
  }
}
    ${MiniBrandFragmentDoc}
${PackagedProductWithoutProductFragmentDoc}`;
export const MiniProductWithCategoryFragmentDoc = gql`
    fragment miniProductWithCategory on Product {
  id
  name
  category
  isPriceCustomizable
  packagedProducts {
    ...packagedProductWithoutProduct
  }
}
    ${PackagedProductWithoutProductFragmentDoc}`;
export const ProposalFragmentDoc = gql`
    fragment proposal on Proposal {
  id
  title
  format
  createdAt
  updatedAt
  expireAt
  brandId
  user {
    firstName
    lastName
    profilePicture
  }
  pdf {
    ...media
  }
  state
  proposalSlides {
    type
    image {
      hd {
        blobUrl
      }
    }
  }
  firstSlide {
    image {
      hd {
        blobUrl
      }
    }
  }
}
    ${MediaFragmentDoc}`;
export const ShortProposalFragmentDoc = gql`
    fragment shortProposal on Proposal {
  id
  pointOfSaleId
  createdAt
  updatedAt
  uuid
  pdf {
    ...media
  }
  state
}
    ${MediaFragmentDoc}`;
export const ProposalInfoFragmentDoc = gql`
    fragment proposalInfo on Proposal {
  id
  title
  brandId
  pointOfSaleId
  expireAt
  commercialConditions {
    packagedProductId
    discountValue
    discountType
    recallValue
    recallType
    amortizationValue
    amortizationType
    packagedProductPrice
    packagedProductUnitsNb
    packagedProduct {
      productId
    }
  }
  proposalAssortments {
    productId
    container
  }
  customCommercialCondition {
    proposalId
    name
    price
    unitsNb
  }
  synthesis {
    id
    proposalId
    discountOther
    adsImport
    adsMonth
    adsLiters
    contributionOther
    loanImport
    loanMonth
    loanLiters
    loanLitersPerYear
    rappelImport
    rappelMonth
    rappelLiters
    discountRappelMonth
    discountRappelLiters
    firstGuaranteeType
    firstGuaranteePercent
    secondGuaranteeType
    secondGuaranteePercent
    adsFirstGuaranteeType
    adsFirstGuaranteePercent
    adsSecondGuaranteeType
    adsSecondGuaranteePercent
    rappel
    discount
    noChargeProducts
    marketingSupport
    pointOfSaleSupport
    plvSupport
    specialDecoration
    estimatedLiters
    notes
  }
  proposalSlides {
    id
    imageId
    type
    order
    proposalId
    image {
      ...image
    }
  }
}
    ${ImageFragmentDoc}`;
export const PackagedProductBeerWithImageFragmentDoc = gql`
    fragment packagedProductBeerWithImage on PackagedProduct {
  id
  name
  productId
  imageId
  isMain
  isActive
  image {
    ...image
  }
  slide {
    ...slide
  }
  croppedImage {
    ...image
  }
  packagedDetails {
    ... on PackagedBeerDetails {
      ...packagedBeerDetails
    }
  }
}
    ${ImageFragmentDoc}
${SlideFragmentDoc}
${PackagedBeerDetailsFragmentDoc}`;
export const RecommendedPackagedProductFragmentDoc = gql`
    fragment recommendedPackagedProduct on RecommendedPackagedProduct {
  soldInLastThreeMonths
  soldInLastMonth
  category
  packagedProduct {
    ...packagedProductBeerWithImage
  }
}
    ${PackagedProductBeerWithImageFragmentDoc}`;
export const ReportingFragmentDoc = gql`
    fragment reporting on Reporting {
  url
}
    `;
export const TrainingFragmentDoc = gql`
    fragment training on Training {
  id
  contentId
  order
  title
  media {
    ...media
  }
}
    ${MediaFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment user on User {
  id
  firstName
  lastName
  role
  email
  phoneNumber
  profilePicture
  externalId: userCode
}
    `;
export const GetProductAccompanimentDocument = gql`
    query GetProductAccompaniment($productId: Int!) {
  products(where: {id: {in: [$productId]}}) {
    accompaniments {
      ...accompaniment
    }
  }
}
    ${AccompanimentFragmentDoc}`;

/**
 * __useGetProductAccompanimentQuery__
 *
 * To run a query within a React component, call `useGetProductAccompanimentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductAccompanimentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductAccompanimentQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetProductAccompanimentQuery(baseOptions: Apollo.QueryHookOptions<GetProductAccompanimentQuery, GetProductAccompanimentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductAccompanimentQuery, GetProductAccompanimentQueryVariables>(GetProductAccompanimentDocument, options);
      }
export function useGetProductAccompanimentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductAccompanimentQuery, GetProductAccompanimentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductAccompanimentQuery, GetProductAccompanimentQueryVariables>(GetProductAccompanimentDocument, options);
        }
export type GetProductAccompanimentQueryHookResult = ReturnType<typeof useGetProductAccompanimentQuery>;
export type GetProductAccompanimentLazyQueryHookResult = ReturnType<typeof useGetProductAccompanimentLazyQuery>;
export type GetProductAccompanimentQueryResult = Apollo.QueryResult<GetProductAccompanimentQuery, GetProductAccompanimentQueryVariables>;
export const GetProductsAccompanimentDocument = gql`
    query GetProductsAccompaniment($productIds: [Int!]) {
  products(where: {id: {in: $productIds}}) {
    id
    accompaniments {
      ...accompaniment
    }
  }
}
    ${AccompanimentFragmentDoc}`;

/**
 * __useGetProductsAccompanimentQuery__
 *
 * To run a query within a React component, call `useGetProductsAccompanimentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsAccompanimentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsAccompanimentQuery({
 *   variables: {
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useGetProductsAccompanimentQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsAccompanimentQuery, GetProductsAccompanimentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsAccompanimentQuery, GetProductsAccompanimentQueryVariables>(GetProductsAccompanimentDocument, options);
      }
export function useGetProductsAccompanimentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsAccompanimentQuery, GetProductsAccompanimentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsAccompanimentQuery, GetProductsAccompanimentQueryVariables>(GetProductsAccompanimentDocument, options);
        }
export type GetProductsAccompanimentQueryHookResult = ReturnType<typeof useGetProductsAccompanimentQuery>;
export type GetProductsAccompanimentLazyQueryHookResult = ReturnType<typeof useGetProductsAccompanimentLazyQuery>;
export type GetProductsAccompanimentQueryResult = Apollo.QueryResult<GetProductsAccompanimentQuery, GetProductsAccompanimentQueryVariables>;
export const GetAllBrandsDocument = gql`
    query GetAllBrands($where: BrandWhereInput) {
  brands(where: $where) {
    ...brand
  }
}
    ${BrandFragmentDoc}`;

/**
 * __useGetAllBrandsQuery__
 *
 * To run a query within a React component, call `useGetAllBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBrandsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllBrandsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBrandsQuery, GetAllBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBrandsQuery, GetAllBrandsQueryVariables>(GetAllBrandsDocument, options);
      }
export function useGetAllBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBrandsQuery, GetAllBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBrandsQuery, GetAllBrandsQueryVariables>(GetAllBrandsDocument, options);
        }
export type GetAllBrandsQueryHookResult = ReturnType<typeof useGetAllBrandsQuery>;
export type GetAllBrandsLazyQueryHookResult = ReturnType<typeof useGetAllBrandsLazyQuery>;
export type GetAllBrandsQueryResult = Apollo.QueryResult<GetAllBrandsQuery, GetAllBrandsQueryVariables>;
export const GetAllMiniBrandsDocument = gql`
    query GetAllMiniBrands($where: BrandWhereInput) {
  brands(where: $where) {
    ...miniBrand
  }
}
    ${MiniBrandFragmentDoc}`;

/**
 * __useGetAllMiniBrandsQuery__
 *
 * To run a query within a React component, call `useGetAllMiniBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMiniBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMiniBrandsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllMiniBrandsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllMiniBrandsQuery, GetAllMiniBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMiniBrandsQuery, GetAllMiniBrandsQueryVariables>(GetAllMiniBrandsDocument, options);
      }
export function useGetAllMiniBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMiniBrandsQuery, GetAllMiniBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMiniBrandsQuery, GetAllMiniBrandsQueryVariables>(GetAllMiniBrandsDocument, options);
        }
export type GetAllMiniBrandsQueryHookResult = ReturnType<typeof useGetAllMiniBrandsQuery>;
export type GetAllMiniBrandsLazyQueryHookResult = ReturnType<typeof useGetAllMiniBrandsLazyQuery>;
export type GetAllMiniBrandsQueryResult = Apollo.QueryResult<GetAllMiniBrandsQuery, GetAllMiniBrandsQueryVariables>;
export const GetAllBrandsWithProductsDocument = gql`
    query GetAllBrandsWithProducts($where: BrandWhereInput) {
  brands(where: $where) {
    ...brandWithProducts
  }
}
    ${BrandWithProductsFragmentDoc}`;

/**
 * __useGetAllBrandsWithProductsQuery__
 *
 * To run a query within a React component, call `useGetAllBrandsWithProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBrandsWithProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBrandsWithProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllBrandsWithProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBrandsWithProductsQuery, GetAllBrandsWithProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBrandsWithProductsQuery, GetAllBrandsWithProductsQueryVariables>(GetAllBrandsWithProductsDocument, options);
      }
export function useGetAllBrandsWithProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBrandsWithProductsQuery, GetAllBrandsWithProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBrandsWithProductsQuery, GetAllBrandsWithProductsQueryVariables>(GetAllBrandsWithProductsDocument, options);
        }
export type GetAllBrandsWithProductsQueryHookResult = ReturnType<typeof useGetAllBrandsWithProductsQuery>;
export type GetAllBrandsWithProductsLazyQueryHookResult = ReturnType<typeof useGetAllBrandsWithProductsLazyQuery>;
export type GetAllBrandsWithProductsQueryResult = Apollo.QueryResult<GetAllBrandsWithProductsQuery, GetAllBrandsWithProductsQueryVariables>;
export const SetCommercialConditionsDocument = gql`
    mutation setCommercialConditions($proposalId: Int!, $isDirectBilling: Boolean!, $isTankDirectBilling: Boolean, $generateSlides: Boolean!, $commercialConditions: [UpsertCommercialConditionInput!]!, $resumeFields: SynthesisInput, $customCommercialCondition: [CustomCommercialConditionsInput!], $provinceNumber: Int) {
  upsertCommercialConditions(
    proposalId: $proposalId
    isDirectBilling: $isDirectBilling
    isTankDirectBilling: $isTankDirectBilling
    commercialConditions: $commercialConditions
    generateSlides: $generateSlides
    synthesis: $resumeFields
    customCommercialCondition: $customCommercialCondition
    provinceNumber: $provinceNumber
  ) {
    ...proposalSlide
  }
}
    ${ProposalSlideFragmentDoc}`;
export type SetCommercialConditionsMutationFn = Apollo.MutationFunction<SetCommercialConditionsMutation, SetCommercialConditionsMutationVariables>;

/**
 * __useSetCommercialConditionsMutation__
 *
 * To run a mutation, you first call `useSetCommercialConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCommercialConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCommercialConditionsMutation, { data, loading, error }] = useSetCommercialConditionsMutation({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *      isDirectBilling: // value for 'isDirectBilling'
 *      isTankDirectBilling: // value for 'isTankDirectBilling'
 *      generateSlides: // value for 'generateSlides'
 *      commercialConditions: // value for 'commercialConditions'
 *      resumeFields: // value for 'resumeFields'
 *      customCommercialCondition: // value for 'customCommercialCondition'
 *      provinceNumber: // value for 'provinceNumber'
 *   },
 * });
 */
export function useSetCommercialConditionsMutation(baseOptions?: Apollo.MutationHookOptions<SetCommercialConditionsMutation, SetCommercialConditionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCommercialConditionsMutation, SetCommercialConditionsMutationVariables>(SetCommercialConditionsDocument, options);
      }
export type SetCommercialConditionsMutationHookResult = ReturnType<typeof useSetCommercialConditionsMutation>;
export type SetCommercialConditionsMutationResult = Apollo.MutationResult<SetCommercialConditionsMutation>;
export type SetCommercialConditionsMutationOptions = Apollo.BaseMutationOptions<SetCommercialConditionsMutation, SetCommercialConditionsMutationVariables>;
export const GetAllLocationsDocument = gql`
    query GetAllLocations($skip: Int, $take: Int, $isOwner: Boolean, $search: [PointOfSaleWhereInput!], $segmentType: SegmentTypeFilter, $segment: SegmentFilter, $brandId: IntFilter, $rentabilibar: Boolean, $beerTAM: FloatFilter, $laLiga: Boolean, $underContract: Boolean, $soldProducts: SoldProductRelationFilter, $latitude: FloatFilter, $longitude: FloatFilter, $address: StringFilter, $clientType: ClientTypeFilter) {
  locations(
    take: $take
    skip: $skip
    isOwner: $isOwner
    where: {latitude: $latitude, longitude: $longitude, address: $address, pointOfSale: {OR: $search, segmentType: $segmentType, segment: $segment, brandId: $brandId, rentabilibar: $rentabilibar, beerTAM: $beerTAM, laLiga: $laLiga, underContract: $underContract, soldProducts: $soldProducts, clientType: $clientType}}
  ) {
    ...location
  }
}
    ${LocationFragmentDoc}`;

/**
 * __useGetAllLocationsQuery__
 *
 * To run a query within a React component, call `useGetAllLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLocationsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      isOwner: // value for 'isOwner'
 *      search: // value for 'search'
 *      segmentType: // value for 'segmentType'
 *      segment: // value for 'segment'
 *      brandId: // value for 'brandId'
 *      rentabilibar: // value for 'rentabilibar'
 *      beerTAM: // value for 'beerTAM'
 *      laLiga: // value for 'laLiga'
 *      underContract: // value for 'underContract'
 *      soldProducts: // value for 'soldProducts'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      address: // value for 'address'
 *      clientType: // value for 'clientType'
 *   },
 * });
 */
export function useGetAllLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllLocationsQuery, GetAllLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllLocationsQuery, GetAllLocationsQueryVariables>(GetAllLocationsDocument, options);
      }
export function useGetAllLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllLocationsQuery, GetAllLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllLocationsQuery, GetAllLocationsQueryVariables>(GetAllLocationsDocument, options);
        }
export type GetAllLocationsQueryHookResult = ReturnType<typeof useGetAllLocationsQuery>;
export type GetAllLocationsLazyQueryHookResult = ReturnType<typeof useGetAllLocationsLazyQuery>;
export type GetAllLocationsQueryResult = Apollo.QueryResult<GetAllLocationsQuery, GetAllLocationsQueryVariables>;
export const BookmarkProductsDocument = gql`
    mutation bookmarkProducts($data: BookmarkedProductsInput!) {
  bookmarkProducts(data: $data) {
    id
  }
}
    `;
export type BookmarkProductsMutationFn = Apollo.MutationFunction<BookmarkProductsMutation, BookmarkProductsMutationVariables>;

/**
 * __useBookmarkProductsMutation__
 *
 * To run a mutation, you first call `useBookmarkProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookmarkProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookmarkProductsMutation, { data, loading, error }] = useBookmarkProductsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBookmarkProductsMutation(baseOptions?: Apollo.MutationHookOptions<BookmarkProductsMutation, BookmarkProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookmarkProductsMutation, BookmarkProductsMutationVariables>(BookmarkProductsDocument, options);
      }
export type BookmarkProductsMutationHookResult = ReturnType<typeof useBookmarkProductsMutation>;
export type BookmarkProductsMutationResult = Apollo.MutationResult<BookmarkProductsMutation>;
export type BookmarkProductsMutationOptions = Apollo.BaseMutationOptions<BookmarkProductsMutation, BookmarkProductsMutationVariables>;
export const GetMeDocument = gql`
    query GetMe {
  me {
    ...user
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetAllNewsDocument = gql`
    query GetAllNews {
  news {
    ...new
  }
}
    ${NewFragmentDoc}`;

/**
 * __useGetAllNewsQuery__
 *
 * To run a query within a React component, call `useGetAllNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllNewsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllNewsQuery, GetAllNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllNewsQuery, GetAllNewsQueryVariables>(GetAllNewsDocument, options);
      }
export function useGetAllNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllNewsQuery, GetAllNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllNewsQuery, GetAllNewsQueryVariables>(GetAllNewsDocument, options);
        }
export type GetAllNewsQueryHookResult = ReturnType<typeof useGetAllNewsQuery>;
export type GetAllNewsLazyQueryHookResult = ReturnType<typeof useGetAllNewsLazyQuery>;
export type GetAllNewsQueryResult = Apollo.QueryResult<GetAllNewsQuery, GetAllNewsQueryVariables>;
export const GetPackagedProductPriceDocument = gql`
    query GetPackagedProductPrice($where: PackagedProductPriceWhereInput) {
  packagedProductPrices(where: $where) {
    packagedProductId
    price
  }
}
    `;

/**
 * __useGetPackagedProductPriceQuery__
 *
 * To run a query within a React component, call `useGetPackagedProductPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackagedProductPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackagedProductPriceQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPackagedProductPriceQuery(baseOptions?: Apollo.QueryHookOptions<GetPackagedProductPriceQuery, GetPackagedProductPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPackagedProductPriceQuery, GetPackagedProductPriceQueryVariables>(GetPackagedProductPriceDocument, options);
      }
export function useGetPackagedProductPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPackagedProductPriceQuery, GetPackagedProductPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPackagedProductPriceQuery, GetPackagedProductPriceQueryVariables>(GetPackagedProductPriceDocument, options);
        }
export type GetPackagedProductPriceQueryHookResult = ReturnType<typeof useGetPackagedProductPriceQuery>;
export type GetPackagedProductPriceLazyQueryHookResult = ReturnType<typeof useGetPackagedProductPriceLazyQuery>;
export type GetPackagedProductPriceQueryResult = Apollo.QueryResult<GetPackagedProductPriceQuery, GetPackagedProductPriceQueryVariables>;
export const ImportPointOfSaleDocument = gql`
    mutation importPointOfSale($externalId: String!) {
  importPointOfSaleFromSherpa(externalId: $externalId) {
    ...pointOfSaleMini
  }
}
    ${PointOfSaleMiniFragmentDoc}`;
export type ImportPointOfSaleMutationFn = Apollo.MutationFunction<ImportPointOfSaleMutation, ImportPointOfSaleMutationVariables>;

/**
 * __useImportPointOfSaleMutation__
 *
 * To run a mutation, you first call `useImportPointOfSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPointOfSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPointOfSaleMutation, { data, loading, error }] = useImportPointOfSaleMutation({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useImportPointOfSaleMutation(baseOptions?: Apollo.MutationHookOptions<ImportPointOfSaleMutation, ImportPointOfSaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportPointOfSaleMutation, ImportPointOfSaleMutationVariables>(ImportPointOfSaleDocument, options);
      }
export type ImportPointOfSaleMutationHookResult = ReturnType<typeof useImportPointOfSaleMutation>;
export type ImportPointOfSaleMutationResult = Apollo.MutationResult<ImportPointOfSaleMutation>;
export type ImportPointOfSaleMutationOptions = Apollo.BaseMutationOptions<ImportPointOfSaleMutation, ImportPointOfSaleMutationVariables>;
export const UpdateSherpaPointOfSaleDocument = gql`
    mutation updateSherpaPointOfSale($externalId: String!, $data: PointOfSaleUpdateInput!) {
  updateSherpaPointOfSale(externalId: $externalId, data: $data) {
    externalId
  }
}
    `;
export type UpdateSherpaPointOfSaleMutationFn = Apollo.MutationFunction<UpdateSherpaPointOfSaleMutation, UpdateSherpaPointOfSaleMutationVariables>;

/**
 * __useUpdateSherpaPointOfSaleMutation__
 *
 * To run a mutation, you first call `useUpdateSherpaPointOfSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSherpaPointOfSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSherpaPointOfSaleMutation, { data, loading, error }] = useUpdateSherpaPointOfSaleMutation({
 *   variables: {
 *      externalId: // value for 'externalId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSherpaPointOfSaleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSherpaPointOfSaleMutation, UpdateSherpaPointOfSaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSherpaPointOfSaleMutation, UpdateSherpaPointOfSaleMutationVariables>(UpdateSherpaPointOfSaleDocument, options);
      }
export type UpdateSherpaPointOfSaleMutationHookResult = ReturnType<typeof useUpdateSherpaPointOfSaleMutation>;
export type UpdateSherpaPointOfSaleMutationResult = Apollo.MutationResult<UpdateSherpaPointOfSaleMutation>;
export type UpdateSherpaPointOfSaleMutationOptions = Apollo.BaseMutationOptions<UpdateSherpaPointOfSaleMutation, UpdateSherpaPointOfSaleMutationVariables>;
export const CreateSherpaPointOfSaleDocument = gql`
    mutation createSherpaPointOfSale($data: PointOfSaleCreateInput!) {
  createSherpaPointOfSale(data: $data) {
    ...pointOfSaleMini
  }
}
    ${PointOfSaleMiniFragmentDoc}`;
export type CreateSherpaPointOfSaleMutationFn = Apollo.MutationFunction<CreateSherpaPointOfSaleMutation, CreateSherpaPointOfSaleMutationVariables>;

/**
 * __useCreateSherpaPointOfSaleMutation__
 *
 * To run a mutation, you first call `useCreateSherpaPointOfSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSherpaPointOfSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSherpaPointOfSaleMutation, { data, loading, error }] = useCreateSherpaPointOfSaleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSherpaPointOfSaleMutation(baseOptions?: Apollo.MutationHookOptions<CreateSherpaPointOfSaleMutation, CreateSherpaPointOfSaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSherpaPointOfSaleMutation, CreateSherpaPointOfSaleMutationVariables>(CreateSherpaPointOfSaleDocument, options);
      }
export type CreateSherpaPointOfSaleMutationHookResult = ReturnType<typeof useCreateSherpaPointOfSaleMutation>;
export type CreateSherpaPointOfSaleMutationResult = Apollo.MutationResult<CreateSherpaPointOfSaleMutation>;
export type CreateSherpaPointOfSaleMutationOptions = Apollo.BaseMutationOptions<CreateSherpaPointOfSaleMutation, CreateSherpaPointOfSaleMutationVariables>;
export const GetAllPosDocument = gql`
    query GetAllPOS($search: String) {
  pointOfSales(where: {name: {contains: $search}}) {
    ...pointOfSaleMini
  }
}
    ${PointOfSaleMiniFragmentDoc}`;

/**
 * __useGetAllPosQuery__
 *
 * To run a query within a React component, call `useGetAllPosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPosQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetAllPosQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPosQuery, GetAllPosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPosQuery, GetAllPosQueryVariables>(GetAllPosDocument, options);
      }
export function useGetAllPosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPosQuery, GetAllPosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPosQuery, GetAllPosQueryVariables>(GetAllPosDocument, options);
        }
export type GetAllPosQueryHookResult = ReturnType<typeof useGetAllPosQuery>;
export type GetAllPosLazyQueryHookResult = ReturnType<typeof useGetAllPosLazyQuery>;
export type GetAllPosQueryResult = Apollo.QueryResult<GetAllPosQuery, GetAllPosQueryVariables>;
export const GetPosDocument = gql`
    query GetPOS($id: Int!) {
  pointOfSales(where: {id: {in: [$id]}}) {
    ...pointOfSale
  }
}
    ${PointOfSaleFragmentDoc}`;

/**
 * __useGetPosQuery__
 *
 * To run a query within a React component, call `useGetPosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPosQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPosQuery(baseOptions: Apollo.QueryHookOptions<GetPosQuery, GetPosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPosQuery, GetPosQueryVariables>(GetPosDocument, options);
      }
export function useGetPosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPosQuery, GetPosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPosQuery, GetPosQueryVariables>(GetPosDocument, options);
        }
export type GetPosQueryHookResult = ReturnType<typeof useGetPosQuery>;
export type GetPosLazyQueryHookResult = ReturnType<typeof useGetPosLazyQuery>;
export type GetPosQueryResult = Apollo.QueryResult<GetPosQuery, GetPosQueryVariables>;
export const GetAssortmentPointOfSaleDocument = gql`
    query GetAssortmentPointOfSale($id: Int!) {
  pointOfSales(where: {id: {in: [$id]}}) {
    ...assortmentPointOfSale
  }
}
    ${AssortmentPointOfSaleFragmentDoc}`;

/**
 * __useGetAssortmentPointOfSaleQuery__
 *
 * To run a query within a React component, call `useGetAssortmentPointOfSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssortmentPointOfSaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssortmentPointOfSaleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssortmentPointOfSaleQuery(baseOptions: Apollo.QueryHookOptions<GetAssortmentPointOfSaleQuery, GetAssortmentPointOfSaleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssortmentPointOfSaleQuery, GetAssortmentPointOfSaleQueryVariables>(GetAssortmentPointOfSaleDocument, options);
      }
export function useGetAssortmentPointOfSaleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssortmentPointOfSaleQuery, GetAssortmentPointOfSaleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssortmentPointOfSaleQuery, GetAssortmentPointOfSaleQueryVariables>(GetAssortmentPointOfSaleDocument, options);
        }
export type GetAssortmentPointOfSaleQueryHookResult = ReturnType<typeof useGetAssortmentPointOfSaleQuery>;
export type GetAssortmentPointOfSaleLazyQueryHookResult = ReturnType<typeof useGetAssortmentPointOfSaleLazyQuery>;
export type GetAssortmentPointOfSaleQueryResult = Apollo.QueryResult<GetAssortmentPointOfSaleQuery, GetAssortmentPointOfSaleQueryVariables>;
export const GetSingleMapPosDocument = gql`
    query GetSingleMapPos($id: Int!) {
  pointOfSales(where: {id: {in: [$id]}}) {
    ...pointOfSaleMap
  }
}
    ${PointOfSaleMapFragmentDoc}`;

/**
 * __useGetSingleMapPosQuery__
 *
 * To run a query within a React component, call `useGetSingleMapPosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleMapPosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleMapPosQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleMapPosQuery(baseOptions: Apollo.QueryHookOptions<GetSingleMapPosQuery, GetSingleMapPosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleMapPosQuery, GetSingleMapPosQueryVariables>(GetSingleMapPosDocument, options);
      }
export function useGetSingleMapPosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleMapPosQuery, GetSingleMapPosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleMapPosQuery, GetSingleMapPosQueryVariables>(GetSingleMapPosDocument, options);
        }
export type GetSingleMapPosQueryHookResult = ReturnType<typeof useGetSingleMapPosQuery>;
export type GetSingleMapPosLazyQueryHookResult = ReturnType<typeof useGetSingleMapPosLazyQuery>;
export type GetSingleMapPosQueryResult = Apollo.QueryResult<GetSingleMapPosQuery, GetSingleMapPosQueryVariables>;
export const GetPosOverviewDocument = gql`
    query GetPosOverview($id: Int!) {
  pointOfSale(where: {id: $id}) {
    ...pointOfSaleOverview
  }
}
    ${PointOfSaleOverviewFragmentDoc}`;

/**
 * __useGetPosOverviewQuery__
 *
 * To run a query within a React component, call `useGetPosOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPosOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPosOverviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPosOverviewQuery(baseOptions: Apollo.QueryHookOptions<GetPosOverviewQuery, GetPosOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPosOverviewQuery, GetPosOverviewQueryVariables>(GetPosOverviewDocument, options);
      }
export function useGetPosOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPosOverviewQuery, GetPosOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPosOverviewQuery, GetPosOverviewQueryVariables>(GetPosOverviewDocument, options);
        }
export type GetPosOverviewQueryHookResult = ReturnType<typeof useGetPosOverviewQuery>;
export type GetPosOverviewLazyQueryHookResult = ReturnType<typeof useGetPosOverviewLazyQuery>;
export type GetPosOverviewQueryResult = Apollo.QueryResult<GetPosOverviewQuery, GetPosOverviewQueryVariables>;
export const GetPosMicroDocument = gql`
    query GetPosMicro($id: Int!) {
  pointOfSale(where: {id: $id}) {
    ...pointOfSaleMicro
  }
}
    ${PointOfSaleMicroFragmentDoc}`;

/**
 * __useGetPosMicroQuery__
 *
 * To run a query within a React component, call `useGetPosMicroQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPosMicroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPosMicroQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPosMicroQuery(baseOptions: Apollo.QueryHookOptions<GetPosMicroQuery, GetPosMicroQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPosMicroQuery, GetPosMicroQueryVariables>(GetPosMicroDocument, options);
      }
export function useGetPosMicroLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPosMicroQuery, GetPosMicroQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPosMicroQuery, GetPosMicroQueryVariables>(GetPosMicroDocument, options);
        }
export type GetPosMicroQueryHookResult = ReturnType<typeof useGetPosMicroQuery>;
export type GetPosMicroLazyQueryHookResult = ReturnType<typeof useGetPosMicroLazyQuery>;
export type GetPosMicroQueryResult = Apollo.QueryResult<GetPosMicroQuery, GetPosMicroQueryVariables>;
export const GetPosVolumeDocument = gql`
    query GetPosVolume($id: Int!) {
  pointOfSale(where: {id: $id}) {
    ...pointOfSaleVolume
  }
}
    ${PointOfSaleVolumeFragmentDoc}`;

/**
 * __useGetPosVolumeQuery__
 *
 * To run a query within a React component, call `useGetPosVolumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPosVolumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPosVolumeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPosVolumeQuery(baseOptions: Apollo.QueryHookOptions<GetPosVolumeQuery, GetPosVolumeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPosVolumeQuery, GetPosVolumeQueryVariables>(GetPosVolumeDocument, options);
      }
export function useGetPosVolumeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPosVolumeQuery, GetPosVolumeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPosVolumeQuery, GetPosVolumeQueryVariables>(GetPosVolumeDocument, options);
        }
export type GetPosVolumeQueryHookResult = ReturnType<typeof useGetPosVolumeQuery>;
export type GetPosVolumeLazyQueryHookResult = ReturnType<typeof useGetPosVolumeLazyQuery>;
export type GetPosVolumeQueryResult = Apollo.QueryResult<GetPosVolumeQuery, GetPosVolumeQueryVariables>;
export const GetPosKpiDocument = gql`
    query GetPosKpi($id: Int!) {
  pointOfSale(where: {id: $id}) {
    ...pointOfSaleKPI
  }
}
    ${PointOfSaleKpiFragmentDoc}`;

/**
 * __useGetPosKpiQuery__
 *
 * To run a query within a React component, call `useGetPosKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPosKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPosKpiQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPosKpiQuery(baseOptions: Apollo.QueryHookOptions<GetPosKpiQuery, GetPosKpiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPosKpiQuery, GetPosKpiQueryVariables>(GetPosKpiDocument, options);
      }
export function useGetPosKpiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPosKpiQuery, GetPosKpiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPosKpiQuery, GetPosKpiQueryVariables>(GetPosKpiDocument, options);
        }
export type GetPosKpiQueryHookResult = ReturnType<typeof useGetPosKpiQuery>;
export type GetPosKpiLazyQueryHookResult = ReturnType<typeof useGetPosKpiLazyQuery>;
export type GetPosKpiQueryResult = Apollo.QueryResult<GetPosKpiQuery, GetPosKpiQueryVariables>;
export const GetFilteredPointOfSalesDocument = gql`
    query GetFilteredPointOfSales($skip: Int, $take: Int, $isOwner: Boolean, $search: [PointOfSaleWhereInput!], $segmentType: SegmentTypeFilter, $segment: SegmentFilter, $brandId: IntFilter, $rentabilibar: Boolean, $beerTAM: FloatFilter, $laLiga: Boolean, $underContract: Boolean, $soldProducts: SoldProductRelationFilter, $userLocation: UserLocationInput, $location: LocationWhereInput, $clientType: ClientTypeFilter, $lastVisitDate: DateFilter) {
  pointOfSales(
    where: {OR: $search, segmentType: $segmentType, segment: $segment, brandId: $brandId, rentabilibar: $rentabilibar, beerTAM: $beerTAM, laLiga: $laLiga, underContract: $underContract, soldProducts: $soldProducts, location: $location, clientType: $clientType, lastVisitDate: $lastVisitDate}
    skip: $skip
    take: $take
    isOwner: $isOwner
    userLocation: $userLocation
  ) {
    ...pointOfSaleMini
  }
}
    ${PointOfSaleMiniFragmentDoc}`;

/**
 * __useGetFilteredPointOfSalesQuery__
 *
 * To run a query within a React component, call `useGetFilteredPointOfSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredPointOfSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredPointOfSalesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      isOwner: // value for 'isOwner'
 *      search: // value for 'search'
 *      segmentType: // value for 'segmentType'
 *      segment: // value for 'segment'
 *      brandId: // value for 'brandId'
 *      rentabilibar: // value for 'rentabilibar'
 *      beerTAM: // value for 'beerTAM'
 *      laLiga: // value for 'laLiga'
 *      underContract: // value for 'underContract'
 *      soldProducts: // value for 'soldProducts'
 *      userLocation: // value for 'userLocation'
 *      location: // value for 'location'
 *      clientType: // value for 'clientType'
 *      lastVisitDate: // value for 'lastVisitDate'
 *   },
 * });
 */
export function useGetFilteredPointOfSalesQuery(baseOptions?: Apollo.QueryHookOptions<GetFilteredPointOfSalesQuery, GetFilteredPointOfSalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilteredPointOfSalesQuery, GetFilteredPointOfSalesQueryVariables>(GetFilteredPointOfSalesDocument, options);
      }
export function useGetFilteredPointOfSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilteredPointOfSalesQuery, GetFilteredPointOfSalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilteredPointOfSalesQuery, GetFilteredPointOfSalesQueryVariables>(GetFilteredPointOfSalesDocument, options);
        }
export type GetFilteredPointOfSalesQueryHookResult = ReturnType<typeof useGetFilteredPointOfSalesQuery>;
export type GetFilteredPointOfSalesLazyQueryHookResult = ReturnType<typeof useGetFilteredPointOfSalesLazyQuery>;
export type GetFilteredPointOfSalesQueryResult = Apollo.QueryResult<GetFilteredPointOfSalesQuery, GetFilteredPointOfSalesQueryVariables>;
export const GetPointOfSalesDocument = gql`
    query GetPointOfSales($id: Int!) {
  pointOfSales(where: {id: {in: [$id]}}) {
    ...pointOfSaleMini
  }
}
    ${PointOfSaleMiniFragmentDoc}`;

/**
 * __useGetPointOfSalesQuery__
 *
 * To run a query within a React component, call `useGetPointOfSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPointOfSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPointOfSalesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPointOfSalesQuery(baseOptions: Apollo.QueryHookOptions<GetPointOfSalesQuery, GetPointOfSalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPointOfSalesQuery, GetPointOfSalesQueryVariables>(GetPointOfSalesDocument, options);
      }
export function useGetPointOfSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPointOfSalesQuery, GetPointOfSalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPointOfSalesQuery, GetPointOfSalesQueryVariables>(GetPointOfSalesDocument, options);
        }
export type GetPointOfSalesQueryHookResult = ReturnType<typeof useGetPointOfSalesQuery>;
export type GetPointOfSalesLazyQueryHookResult = ReturnType<typeof useGetPointOfSalesLazyQuery>;
export type GetPointOfSalesQueryResult = Apollo.QueryResult<GetPointOfSalesQuery, GetPointOfSalesQueryVariables>;
export const GetPointOfSalesTypesDocument = gql`
    query GetPointOfSalesTypes {
  pointOfSaleType {
    value
    label
  }
}
    `;

/**
 * __useGetPointOfSalesTypesQuery__
 *
 * To run a query within a React component, call `useGetPointOfSalesTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPointOfSalesTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPointOfSalesTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPointOfSalesTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetPointOfSalesTypesQuery, GetPointOfSalesTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPointOfSalesTypesQuery, GetPointOfSalesTypesQueryVariables>(GetPointOfSalesTypesDocument, options);
      }
export function useGetPointOfSalesTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPointOfSalesTypesQuery, GetPointOfSalesTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPointOfSalesTypesQuery, GetPointOfSalesTypesQueryVariables>(GetPointOfSalesTypesDocument, options);
        }
export type GetPointOfSalesTypesQueryHookResult = ReturnType<typeof useGetPointOfSalesTypesQuery>;
export type GetPointOfSalesTypesLazyQueryHookResult = ReturnType<typeof useGetPointOfSalesTypesLazyQuery>;
export type GetPointOfSalesTypesQueryResult = Apollo.QueryResult<GetPointOfSalesTypesQuery, GetPointOfSalesTypesQueryVariables>;
export const GetPointOfSalesClassificationDocument = gql`
    query GetPointOfSalesClassification {
  pointOfSaleClassification {
    value
    label
  }
}
    `;

/**
 * __useGetPointOfSalesClassificationQuery__
 *
 * To run a query within a React component, call `useGetPointOfSalesClassificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPointOfSalesClassificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPointOfSalesClassificationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPointOfSalesClassificationQuery(baseOptions?: Apollo.QueryHookOptions<GetPointOfSalesClassificationQuery, GetPointOfSalesClassificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPointOfSalesClassificationQuery, GetPointOfSalesClassificationQueryVariables>(GetPointOfSalesClassificationDocument, options);
      }
export function useGetPointOfSalesClassificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPointOfSalesClassificationQuery, GetPointOfSalesClassificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPointOfSalesClassificationQuery, GetPointOfSalesClassificationQueryVariables>(GetPointOfSalesClassificationDocument, options);
        }
export type GetPointOfSalesClassificationQueryHookResult = ReturnType<typeof useGetPointOfSalesClassificationQuery>;
export type GetPointOfSalesClassificationLazyQueryHookResult = ReturnType<typeof useGetPointOfSalesClassificationLazyQuery>;
export type GetPointOfSalesClassificationQueryResult = Apollo.QueryResult<GetPointOfSalesClassificationQuery, GetPointOfSalesClassificationQueryVariables>;
export const CreatePointOfSaleSlidesDocument = gql`
    mutation createPointOfSaleSlides($pointOfSaleId: Int!, $type: [PointOfSaleSlideType!]) {
  createPointOfSaleSlides(pointOfSaleId: $pointOfSaleId, type: $type) {
    ...pointOfSaleSlide
  }
}
    ${PointOfSaleSlideFragmentDoc}`;
export type CreatePointOfSaleSlidesMutationFn = Apollo.MutationFunction<CreatePointOfSaleSlidesMutation, CreatePointOfSaleSlidesMutationVariables>;

/**
 * __useCreatePointOfSaleSlidesMutation__
 *
 * To run a mutation, you first call `useCreatePointOfSaleSlidesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePointOfSaleSlidesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPointOfSaleSlidesMutation, { data, loading, error }] = useCreatePointOfSaleSlidesMutation({
 *   variables: {
 *      pointOfSaleId: // value for 'pointOfSaleId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreatePointOfSaleSlidesMutation(baseOptions?: Apollo.MutationHookOptions<CreatePointOfSaleSlidesMutation, CreatePointOfSaleSlidesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePointOfSaleSlidesMutation, CreatePointOfSaleSlidesMutationVariables>(CreatePointOfSaleSlidesDocument, options);
      }
export type CreatePointOfSaleSlidesMutationHookResult = ReturnType<typeof useCreatePointOfSaleSlidesMutation>;
export type CreatePointOfSaleSlidesMutationResult = Apollo.MutationResult<CreatePointOfSaleSlidesMutation>;
export type CreatePointOfSaleSlidesMutationOptions = Apollo.BaseMutationOptions<CreatePointOfSaleSlidesMutation, CreatePointOfSaleSlidesMutationVariables>;
export const CreateNotBeerSlidesDocument = gql`
    mutation createNotBeerSlides($proposalId: Int!) {
  createNotBeerSlides(proposalId: $proposalId) {
    ...pointOfSaleSlide
  }
}
    ${PointOfSaleSlideFragmentDoc}`;
export type CreateNotBeerSlidesMutationFn = Apollo.MutationFunction<CreateNotBeerSlidesMutation, CreateNotBeerSlidesMutationVariables>;

/**
 * __useCreateNotBeerSlidesMutation__
 *
 * To run a mutation, you first call `useCreateNotBeerSlidesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotBeerSlidesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotBeerSlidesMutation, { data, loading, error }] = useCreateNotBeerSlidesMutation({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useCreateNotBeerSlidesMutation(baseOptions?: Apollo.MutationHookOptions<CreateNotBeerSlidesMutation, CreateNotBeerSlidesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNotBeerSlidesMutation, CreateNotBeerSlidesMutationVariables>(CreateNotBeerSlidesDocument, options);
      }
export type CreateNotBeerSlidesMutationHookResult = ReturnType<typeof useCreateNotBeerSlidesMutation>;
export type CreateNotBeerSlidesMutationResult = Apollo.MutationResult<CreateNotBeerSlidesMutation>;
export type CreateNotBeerSlidesMutationOptions = Apollo.BaseMutationOptions<CreateNotBeerSlidesMutation, CreateNotBeerSlidesMutationVariables>;
export const CreateNotBeerSlidesNewDocument = gql`
    mutation createNotBeerSlidesNew($proposalId: Int!) {
  createNotBeerSlidesNew(proposalId: $proposalId) {
    ...pointOfSaleSlideNew
  }
}
    ${PointOfSaleSlideNewFragmentDoc}`;
export type CreateNotBeerSlidesNewMutationFn = Apollo.MutationFunction<CreateNotBeerSlidesNewMutation, CreateNotBeerSlidesNewMutationVariables>;

/**
 * __useCreateNotBeerSlidesNewMutation__
 *
 * To run a mutation, you first call `useCreateNotBeerSlidesNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotBeerSlidesNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotBeerSlidesNewMutation, { data, loading, error }] = useCreateNotBeerSlidesNewMutation({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useCreateNotBeerSlidesNewMutation(baseOptions?: Apollo.MutationHookOptions<CreateNotBeerSlidesNewMutation, CreateNotBeerSlidesNewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNotBeerSlidesNewMutation, CreateNotBeerSlidesNewMutationVariables>(CreateNotBeerSlidesNewDocument, options);
      }
export type CreateNotBeerSlidesNewMutationHookResult = ReturnType<typeof useCreateNotBeerSlidesNewMutation>;
export type CreateNotBeerSlidesNewMutationResult = Apollo.MutationResult<CreateNotBeerSlidesNewMutation>;
export type CreateNotBeerSlidesNewMutationOptions = Apollo.BaseMutationOptions<CreateNotBeerSlidesNewMutation, CreateNotBeerSlidesNewMutationVariables>;
export const UpdateProposalSlidesDocument = gql`
    mutation updateProposalSlides($proposalId: Int!, $slides: [ProposalSlideInput!]!) {
  updateProposalSlides(proposalId: $proposalId, slides: $slides) {
    id
  }
}
    `;
export type UpdateProposalSlidesMutationFn = Apollo.MutationFunction<UpdateProposalSlidesMutation, UpdateProposalSlidesMutationVariables>;

/**
 * __useUpdateProposalSlidesMutation__
 *
 * To run a mutation, you first call `useUpdateProposalSlidesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProposalSlidesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProposalSlidesMutation, { data, loading, error }] = useUpdateProposalSlidesMutation({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *      slides: // value for 'slides'
 *   },
 * });
 */
export function useUpdateProposalSlidesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProposalSlidesMutation, UpdateProposalSlidesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProposalSlidesMutation, UpdateProposalSlidesMutationVariables>(UpdateProposalSlidesDocument, options);
      }
export type UpdateProposalSlidesMutationHookResult = ReturnType<typeof useUpdateProposalSlidesMutation>;
export type UpdateProposalSlidesMutationResult = Apollo.MutationResult<UpdateProposalSlidesMutation>;
export type UpdateProposalSlidesMutationOptions = Apollo.BaseMutationOptions<UpdateProposalSlidesMutation, UpdateProposalSlidesMutationVariables>;
export const AddProposalProductsDocument = gql`
    mutation addProposalProducts($proposalId: Int!, $brandId: Int!, $products: [ProductIdAndType!]!) {
  addProposalProducts(
    proposalId: $proposalId
    brandId: $brandId
    products: $products
  ) {
    ...proposalSlide
  }
}
    ${ProposalSlideFragmentDoc}`;
export type AddProposalProductsMutationFn = Apollo.MutationFunction<AddProposalProductsMutation, AddProposalProductsMutationVariables>;

/**
 * __useAddProposalProductsMutation__
 *
 * To run a mutation, you first call `useAddProposalProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProposalProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProposalProductsMutation, { data, loading, error }] = useAddProposalProductsMutation({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *      brandId: // value for 'brandId'
 *      products: // value for 'products'
 *   },
 * });
 */
export function useAddProposalProductsMutation(baseOptions?: Apollo.MutationHookOptions<AddProposalProductsMutation, AddProposalProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProposalProductsMutation, AddProposalProductsMutationVariables>(AddProposalProductsDocument, options);
      }
export type AddProposalProductsMutationHookResult = ReturnType<typeof useAddProposalProductsMutation>;
export type AddProposalProductsMutationResult = Apollo.MutationResult<AddProposalProductsMutation>;
export type AddProposalProductsMutationOptions = Apollo.BaseMutationOptions<AddProposalProductsMutation, AddProposalProductsMutationVariables>;
export const UpdateProposalProductsDocument = gql`
    mutation updateProposalProducts($kegBaseId: Int!, $proposalId: Int!, $products: [ProductIdAndType!]!) {
  updateProposalProducts(
    brandId: $kegBaseId
    proposalId: $proposalId
    products: $products
  ) {
    id
  }
}
    `;
export type UpdateProposalProductsMutationFn = Apollo.MutationFunction<UpdateProposalProductsMutation, UpdateProposalProductsMutationVariables>;

/**
 * __useUpdateProposalProductsMutation__
 *
 * To run a mutation, you first call `useUpdateProposalProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProposalProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProposalProductsMutation, { data, loading, error }] = useUpdateProposalProductsMutation({
 *   variables: {
 *      kegBaseId: // value for 'kegBaseId'
 *      proposalId: // value for 'proposalId'
 *      products: // value for 'products'
 *   },
 * });
 */
export function useUpdateProposalProductsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProposalProductsMutation, UpdateProposalProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProposalProductsMutation, UpdateProposalProductsMutationVariables>(UpdateProposalProductsDocument, options);
      }
export type UpdateProposalProductsMutationHookResult = ReturnType<typeof useUpdateProposalProductsMutation>;
export type UpdateProposalProductsMutationResult = Apollo.MutationResult<UpdateProposalProductsMutation>;
export type UpdateProposalProductsMutationOptions = Apollo.BaseMutationOptions<UpdateProposalProductsMutation, UpdateProposalProductsMutationVariables>;
export const CreatePointOfSaleVisitDocument = gql`
    mutation createPointOfSaleVisit($pointOfSaleId: Int!, $data: PointOfSaleVisitInput!) {
  createPointOfSaleVisit(where: {id: $pointOfSaleId}, data: $data)
}
    `;
export type CreatePointOfSaleVisitMutationFn = Apollo.MutationFunction<CreatePointOfSaleVisitMutation, CreatePointOfSaleVisitMutationVariables>;

/**
 * __useCreatePointOfSaleVisitMutation__
 *
 * To run a mutation, you first call `useCreatePointOfSaleVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePointOfSaleVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPointOfSaleVisitMutation, { data, loading, error }] = useCreatePointOfSaleVisitMutation({
 *   variables: {
 *      pointOfSaleId: // value for 'pointOfSaleId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePointOfSaleVisitMutation(baseOptions?: Apollo.MutationHookOptions<CreatePointOfSaleVisitMutation, CreatePointOfSaleVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePointOfSaleVisitMutation, CreatePointOfSaleVisitMutationVariables>(CreatePointOfSaleVisitDocument, options);
      }
export type CreatePointOfSaleVisitMutationHookResult = ReturnType<typeof useCreatePointOfSaleVisitMutation>;
export type CreatePointOfSaleVisitMutationResult = Apollo.MutationResult<CreatePointOfSaleVisitMutation>;
export type CreatePointOfSaleVisitMutationOptions = Apollo.BaseMutationOptions<CreatePointOfSaleVisitMutation, CreatePointOfSaleVisitMutationVariables>;
export const UpdatePointOfSaleVisitDocument = gql`
    mutation updatePointOfSaleVisit($visitExternalId: String!, $data: PointOfSaleVisitInput!) {
  updatePointOfSaleVisit(externalId: $visitExternalId, data: $data)
}
    `;
export type UpdatePointOfSaleVisitMutationFn = Apollo.MutationFunction<UpdatePointOfSaleVisitMutation, UpdatePointOfSaleVisitMutationVariables>;

/**
 * __useUpdatePointOfSaleVisitMutation__
 *
 * To run a mutation, you first call `useUpdatePointOfSaleVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePointOfSaleVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePointOfSaleVisitMutation, { data, loading, error }] = useUpdatePointOfSaleVisitMutation({
 *   variables: {
 *      visitExternalId: // value for 'visitExternalId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePointOfSaleVisitMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePointOfSaleVisitMutation, UpdatePointOfSaleVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePointOfSaleVisitMutation, UpdatePointOfSaleVisitMutationVariables>(UpdatePointOfSaleVisitDocument, options);
      }
export type UpdatePointOfSaleVisitMutationHookResult = ReturnType<typeof useUpdatePointOfSaleVisitMutation>;
export type UpdatePointOfSaleVisitMutationResult = Apollo.MutationResult<UpdatePointOfSaleVisitMutation>;
export type UpdatePointOfSaleVisitMutationOptions = Apollo.BaseMutationOptions<UpdatePointOfSaleVisitMutation, UpdatePointOfSaleVisitMutationVariables>;
export const PointOfSaleVisitsDocument = gql`
    query pointOfSaleVisits($externalId: String!, $startDate: DateFilter) {
  pointOfSaleVisits(where: {externalId: $externalId, startDate: $startDate}) {
    ...pointOfSaleVisit
  }
}
    ${PointOfSaleVisitFragmentDoc}`;

/**
 * __usePointOfSaleVisitsQuery__
 *
 * To run a query within a React component, call `usePointOfSaleVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePointOfSaleVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePointOfSaleVisitsQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function usePointOfSaleVisitsQuery(baseOptions: Apollo.QueryHookOptions<PointOfSaleVisitsQuery, PointOfSaleVisitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PointOfSaleVisitsQuery, PointOfSaleVisitsQueryVariables>(PointOfSaleVisitsDocument, options);
      }
export function usePointOfSaleVisitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PointOfSaleVisitsQuery, PointOfSaleVisitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PointOfSaleVisitsQuery, PointOfSaleVisitsQueryVariables>(PointOfSaleVisitsDocument, options);
        }
export type PointOfSaleVisitsQueryHookResult = ReturnType<typeof usePointOfSaleVisitsQuery>;
export type PointOfSaleVisitsLazyQueryHookResult = ReturnType<typeof usePointOfSaleVisitsLazyQuery>;
export type PointOfSaleVisitsQueryResult = Apollo.QueryResult<PointOfSaleVisitsQuery, PointOfSaleVisitsQueryVariables>;
export const PointOfSaleVisitWholesaleContactDocument = gql`
    query pointOfSaleVisitWholesaleContact($posId: Int!) {
  pointOfSaleVisitWholesaleContact(where: {id: $posId}) {
    label
    externalId: value
  }
}
    `;

/**
 * __usePointOfSaleVisitWholesaleContactQuery__
 *
 * To run a query within a React component, call `usePointOfSaleVisitWholesaleContactQuery` and pass it any options that fit your needs.
 * When your component renders, `usePointOfSaleVisitWholesaleContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePointOfSaleVisitWholesaleContactQuery({
 *   variables: {
 *      posId: // value for 'posId'
 *   },
 * });
 */
export function usePointOfSaleVisitWholesaleContactQuery(baseOptions: Apollo.QueryHookOptions<PointOfSaleVisitWholesaleContactQuery, PointOfSaleVisitWholesaleContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PointOfSaleVisitWholesaleContactQuery, PointOfSaleVisitWholesaleContactQueryVariables>(PointOfSaleVisitWholesaleContactDocument, options);
      }
export function usePointOfSaleVisitWholesaleContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PointOfSaleVisitWholesaleContactQuery, PointOfSaleVisitWholesaleContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PointOfSaleVisitWholesaleContactQuery, PointOfSaleVisitWholesaleContactQueryVariables>(PointOfSaleVisitWholesaleContactDocument, options);
        }
export type PointOfSaleVisitWholesaleContactQueryHookResult = ReturnType<typeof usePointOfSaleVisitWholesaleContactQuery>;
export type PointOfSaleVisitWholesaleContactLazyQueryHookResult = ReturnType<typeof usePointOfSaleVisitWholesaleContactLazyQuery>;
export type PointOfSaleVisitWholesaleContactQueryResult = Apollo.QueryResult<PointOfSaleVisitWholesaleContactQuery, PointOfSaleVisitWholesaleContactQueryVariables>;
export const GetProductsDocument = gql`
    query GetProducts($where: ProductsWhereInput, $take: Int, $skip: Int) {
  products(where: $where, take: $take, skip: $skip) {
    ...product
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export const GetProductsWithPricesDocument = gql`
    query GetProductsWithPrices($where: ProductsWhereInput, $take: Int, $skip: Int, $pointOfSaleId: Float, $provinceNumber: Int) {
  products(where: $where, take: $take, skip: $skip) {
    ...productWithPrices
  }
}
    ${ProductWithPricesFragmentDoc}`;

/**
 * __useGetProductsWithPricesQuery__
 *
 * To run a query within a React component, call `useGetProductsWithPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsWithPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsWithPricesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      pointOfSaleId: // value for 'pointOfSaleId'
 *      provinceNumber: // value for 'provinceNumber'
 *   },
 * });
 */
export function useGetProductsWithPricesQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsWithPricesQuery, GetProductsWithPricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsWithPricesQuery, GetProductsWithPricesQueryVariables>(GetProductsWithPricesDocument, options);
      }
export function useGetProductsWithPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsWithPricesQuery, GetProductsWithPricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsWithPricesQuery, GetProductsWithPricesQueryVariables>(GetProductsWithPricesDocument, options);
        }
export type GetProductsWithPricesQueryHookResult = ReturnType<typeof useGetProductsWithPricesQuery>;
export type GetProductsWithPricesLazyQueryHookResult = ReturnType<typeof useGetProductsWithPricesLazyQuery>;
export type GetProductsWithPricesQueryResult = Apollo.QueryResult<GetProductsWithPricesQuery, GetProductsWithPricesQueryVariables>;
export const GetProductDocument = gql`
    query GetProduct($id: Int!, $name: String, $take: Int, $skip: Int) {
  products(
    where: {id: {in: [$id]}, name: {contains: $name}}
    take: $take
    skip: $skip
  ) {
    ...product
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions: Apollo.QueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
      }
export function useGetProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = Apollo.QueryResult<GetProductQuery, GetProductQueryVariables>;
export const GetProductWithPriceDocument = gql`
    query GetProductWithPrice($id: Int!, $name: String, $take: Int, $skip: Int, $pointOfSaleId: Float, $provinceNumber: Int) {
  products(
    where: {id: {in: [$id]}, name: {contains: $name}}
    take: $take
    skip: $skip
  ) {
    ...productWithPrices
  }
}
    ${ProductWithPricesFragmentDoc}`;

/**
 * __useGetProductWithPriceQuery__
 *
 * To run a query within a React component, call `useGetProductWithPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductWithPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductWithPriceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      pointOfSaleId: // value for 'pointOfSaleId'
 *      provinceNumber: // value for 'provinceNumber'
 *   },
 * });
 */
export function useGetProductWithPriceQuery(baseOptions: Apollo.QueryHookOptions<GetProductWithPriceQuery, GetProductWithPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductWithPriceQuery, GetProductWithPriceQueryVariables>(GetProductWithPriceDocument, options);
      }
export function useGetProductWithPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductWithPriceQuery, GetProductWithPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductWithPriceQuery, GetProductWithPriceQueryVariables>(GetProductWithPriceDocument, options);
        }
export type GetProductWithPriceQueryHookResult = ReturnType<typeof useGetProductWithPriceQuery>;
export type GetProductWithPriceLazyQueryHookResult = ReturnType<typeof useGetProductWithPriceLazyQuery>;
export type GetProductWithPriceQueryResult = Apollo.QueryResult<GetProductWithPriceQuery, GetProductWithPriceQueryVariables>;
export const GetProductsWithPriceDocument = gql`
    query GetProductsWithPrice($ids: [Int!], $name: String, $take: Int, $skip: Int, $pointOfSaleId: Float, $provinceNumber: Int) {
  products(
    where: {id: {in: $ids}, name: {contains: $name}}
    take: $take
    skip: $skip
  ) {
    ...productWithPrices
  }
}
    ${ProductWithPricesFragmentDoc}`;

/**
 * __useGetProductsWithPriceQuery__
 *
 * To run a query within a React component, call `useGetProductsWithPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsWithPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsWithPriceQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      name: // value for 'name'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      pointOfSaleId: // value for 'pointOfSaleId'
 *      provinceNumber: // value for 'provinceNumber'
 *   },
 * });
 */
export function useGetProductsWithPriceQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsWithPriceQuery, GetProductsWithPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsWithPriceQuery, GetProductsWithPriceQueryVariables>(GetProductsWithPriceDocument, options);
      }
export function useGetProductsWithPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsWithPriceQuery, GetProductsWithPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsWithPriceQuery, GetProductsWithPriceQueryVariables>(GetProductsWithPriceDocument, options);
        }
export type GetProductsWithPriceQueryHookResult = ReturnType<typeof useGetProductsWithPriceQuery>;
export type GetProductsWithPriceLazyQueryHookResult = ReturnType<typeof useGetProductsWithPriceLazyQuery>;
export type GetProductsWithPriceQueryResult = Apollo.QueryResult<GetProductsWithPriceQuery, GetProductsWithPriceQueryVariables>;
export const GetMiniProductsWithCategoryDocument = gql`
    query GetMiniProductsWithCategory($ids: [Int!]) {
  products(where: {id: {in: $ids}}) {
    ...miniProductWithCategory
  }
}
    ${MiniProductWithCategoryFragmentDoc}`;

/**
 * __useGetMiniProductsWithCategoryQuery__
 *
 * To run a query within a React component, call `useGetMiniProductsWithCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMiniProductsWithCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMiniProductsWithCategoryQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetMiniProductsWithCategoryQuery(baseOptions?: Apollo.QueryHookOptions<GetMiniProductsWithCategoryQuery, GetMiniProductsWithCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMiniProductsWithCategoryQuery, GetMiniProductsWithCategoryQueryVariables>(GetMiniProductsWithCategoryDocument, options);
      }
export function useGetMiniProductsWithCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMiniProductsWithCategoryQuery, GetMiniProductsWithCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMiniProductsWithCategoryQuery, GetMiniProductsWithCategoryQueryVariables>(GetMiniProductsWithCategoryDocument, options);
        }
export type GetMiniProductsWithCategoryQueryHookResult = ReturnType<typeof useGetMiniProductsWithCategoryQuery>;
export type GetMiniProductsWithCategoryLazyQueryHookResult = ReturnType<typeof useGetMiniProductsWithCategoryLazyQuery>;
export type GetMiniProductsWithCategoryQueryResult = Apollo.QueryResult<GetMiniProductsWithCategoryQuery, GetMiniProductsWithCategoryQueryVariables>;
export const GetProductsWithoutSlidesDocument = gql`
    query GetProductsWithoutSlides($where: ProductsWhereInput) {
  products(where: $where) {
    ...productWithoutSlide
  }
}
    ${ProductWithoutSlideFragmentDoc}`;

/**
 * __useGetProductsWithoutSlidesQuery__
 *
 * To run a query within a React component, call `useGetProductsWithoutSlidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsWithoutSlidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsWithoutSlidesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetProductsWithoutSlidesQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsWithoutSlidesQuery, GetProductsWithoutSlidesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsWithoutSlidesQuery, GetProductsWithoutSlidesQueryVariables>(GetProductsWithoutSlidesDocument, options);
      }
export function useGetProductsWithoutSlidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsWithoutSlidesQuery, GetProductsWithoutSlidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsWithoutSlidesQuery, GetProductsWithoutSlidesQueryVariables>(GetProductsWithoutSlidesDocument, options);
        }
export type GetProductsWithoutSlidesQueryHookResult = ReturnType<typeof useGetProductsWithoutSlidesQuery>;
export type GetProductsWithoutSlidesLazyQueryHookResult = ReturnType<typeof useGetProductsWithoutSlidesLazyQuery>;
export type GetProductsWithoutSlidesQueryResult = Apollo.QueryResult<GetProductsWithoutSlidesQuery, GetProductsWithoutSlidesQueryVariables>;
export const GetAllProposalsDocument = gql`
    query GetAllProposals {
  proposals {
    ...proposal
  }
}
    ${ProposalFragmentDoc}`;

/**
 * __useGetAllProposalsQuery__
 *
 * To run a query within a React component, call `useGetAllProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProposalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProposalsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllProposalsQuery, GetAllProposalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllProposalsQuery, GetAllProposalsQueryVariables>(GetAllProposalsDocument, options);
      }
export function useGetAllProposalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllProposalsQuery, GetAllProposalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllProposalsQuery, GetAllProposalsQueryVariables>(GetAllProposalsDocument, options);
        }
export type GetAllProposalsQueryHookResult = ReturnType<typeof useGetAllProposalsQuery>;
export type GetAllProposalsLazyQueryHookResult = ReturnType<typeof useGetAllProposalsLazyQuery>;
export type GetAllProposalsQueryResult = Apollo.QueryResult<GetAllProposalsQuery, GetAllProposalsQueryVariables>;
export const GetMeProposalsDocument = gql`
    query GetMeProposals($state: ProposalStates) {
  proposals(state: $state) {
    ...proposal
  }
}
    ${ProposalFragmentDoc}`;

/**
 * __useGetMeProposalsQuery__
 *
 * To run a query within a React component, call `useGetMeProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeProposalsQuery({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useGetMeProposalsQuery(baseOptions?: Apollo.QueryHookOptions<GetMeProposalsQuery, GetMeProposalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeProposalsQuery, GetMeProposalsQueryVariables>(GetMeProposalsDocument, options);
      }
export function useGetMeProposalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeProposalsQuery, GetMeProposalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeProposalsQuery, GetMeProposalsQueryVariables>(GetMeProposalsDocument, options);
        }
export type GetMeProposalsQueryHookResult = ReturnType<typeof useGetMeProposalsQuery>;
export type GetMeProposalsLazyQueryHookResult = ReturnType<typeof useGetMeProposalsLazyQuery>;
export type GetMeProposalsQueryResult = Apollo.QueryResult<GetMeProposalsQuery, GetMeProposalsQueryVariables>;
export const GetPosProposalsDocument = gql`
    query GetPOSProposals($posIds: [Int!], $take: Int, $skip: Int) {
  proposals(pointOfSaleId: {in: $posIds}, take: $take, skip: $skip) {
    ...proposal
  }
}
    ${ProposalFragmentDoc}`;

/**
 * __useGetPosProposalsQuery__
 *
 * To run a query within a React component, call `useGetPosProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPosProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPosProposalsQuery({
 *   variables: {
 *      posIds: // value for 'posIds'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetPosProposalsQuery(baseOptions?: Apollo.QueryHookOptions<GetPosProposalsQuery, GetPosProposalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPosProposalsQuery, GetPosProposalsQueryVariables>(GetPosProposalsDocument, options);
      }
export function useGetPosProposalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPosProposalsQuery, GetPosProposalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPosProposalsQuery, GetPosProposalsQueryVariables>(GetPosProposalsDocument, options);
        }
export type GetPosProposalsQueryHookResult = ReturnType<typeof useGetPosProposalsQuery>;
export type GetPosProposalsLazyQueryHookResult = ReturnType<typeof useGetPosProposalsLazyQuery>;
export type GetPosProposalsQueryResult = Apollo.QueryResult<GetPosProposalsQuery, GetPosProposalsQueryVariables>;
export const GetPosProposalsLastDocument = gql`
    query GetPOSProposalsLast($posIds: [Int!], $take: Int, $skip: Int) {
  proposals(pointOfSaleId: {in: $posIds}, take: $take, skip: $skip) {
    ...proposal
  }
}
    ${ProposalFragmentDoc}`;

/**
 * __useGetPosProposalsLastQuery__
 *
 * To run a query within a React component, call `useGetPosProposalsLastQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPosProposalsLastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPosProposalsLastQuery({
 *   variables: {
 *      posIds: // value for 'posIds'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetPosProposalsLastQuery(baseOptions?: Apollo.QueryHookOptions<GetPosProposalsLastQuery, GetPosProposalsLastQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPosProposalsLastQuery, GetPosProposalsLastQueryVariables>(GetPosProposalsLastDocument, options);
      }
export function useGetPosProposalsLastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPosProposalsLastQuery, GetPosProposalsLastQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPosProposalsLastQuery, GetPosProposalsLastQueryVariables>(GetPosProposalsLastDocument, options);
        }
export type GetPosProposalsLastQueryHookResult = ReturnType<typeof useGetPosProposalsLastQuery>;
export type GetPosProposalsLastLazyQueryHookResult = ReturnType<typeof useGetPosProposalsLastLazyQuery>;
export type GetPosProposalsLastQueryResult = Apollo.QueryResult<GetPosProposalsLastQuery, GetPosProposalsLastQueryVariables>;
export const GetProposalInformationDocument = gql`
    query GetProposalInformation($proposalId: Int!) {
  proposal(where: {id: $proposalId}) {
    ...proposalInfo
  }
}
    ${ProposalInfoFragmentDoc}`;

/**
 * __useGetProposalInformationQuery__
 *
 * To run a query within a React component, call `useGetProposalInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalInformationQuery({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useGetProposalInformationQuery(baseOptions: Apollo.QueryHookOptions<GetProposalInformationQuery, GetProposalInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProposalInformationQuery, GetProposalInformationQueryVariables>(GetProposalInformationDocument, options);
      }
export function useGetProposalInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProposalInformationQuery, GetProposalInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProposalInformationQuery, GetProposalInformationQueryVariables>(GetProposalInformationDocument, options);
        }
export type GetProposalInformationQueryHookResult = ReturnType<typeof useGetProposalInformationQuery>;
export type GetProposalInformationLazyQueryHookResult = ReturnType<typeof useGetProposalInformationLazyQuery>;
export type GetProposalInformationQueryResult = Apollo.QueryResult<GetProposalInformationQuery, GetProposalInformationQueryVariables>;
export const CreateProposalDocument = gql`
    mutation createProposal($title: String!, $pointOfSaleId: Int, $displayedTitle: String, $expireAt: DateTime!) {
  createProposal(
    pointOfSaleId: $pointOfSaleId
    displayedTitle: $displayedTitle
    title: $title
    expireAt: $expireAt
  ) {
    id
    pointOfSaleId
    createdAt
    updatedAt
    state
  }
}
    `;
export type CreateProposalMutationFn = Apollo.MutationFunction<CreateProposalMutation, CreateProposalMutationVariables>;

/**
 * __useCreateProposalMutation__
 *
 * To run a mutation, you first call `useCreateProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProposalMutation, { data, loading, error }] = useCreateProposalMutation({
 *   variables: {
 *      title: // value for 'title'
 *      pointOfSaleId: // value for 'pointOfSaleId'
 *      displayedTitle: // value for 'displayedTitle'
 *      expireAt: // value for 'expireAt'
 *   },
 * });
 */
export function useCreateProposalMutation(baseOptions?: Apollo.MutationHookOptions<CreateProposalMutation, CreateProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProposalMutation, CreateProposalMutationVariables>(CreateProposalDocument, options);
      }
export type CreateProposalMutationHookResult = ReturnType<typeof useCreateProposalMutation>;
export type CreateProposalMutationResult = Apollo.MutationResult<CreateProposalMutation>;
export type CreateProposalMutationOptions = Apollo.BaseMutationOptions<CreateProposalMutation, CreateProposalMutationVariables>;
export const CreateProposalMediasDocument = gql`
    mutation createProposalMedias($proposalId: Int!) {
  generateProposalPdf(where: {id: $proposalId}) {
    pdf {
      ...media
    }
    pptx {
      ...media
    }
  }
}
    ${MediaFragmentDoc}`;
export type CreateProposalMediasMutationFn = Apollo.MutationFunction<CreateProposalMediasMutation, CreateProposalMediasMutationVariables>;

/**
 * __useCreateProposalMediasMutation__
 *
 * To run a mutation, you first call `useCreateProposalMediasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProposalMediasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProposalMediasMutation, { data, loading, error }] = useCreateProposalMediasMutation({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useCreateProposalMediasMutation(baseOptions?: Apollo.MutationHookOptions<CreateProposalMediasMutation, CreateProposalMediasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProposalMediasMutation, CreateProposalMediasMutationVariables>(CreateProposalMediasDocument, options);
      }
export type CreateProposalMediasMutationHookResult = ReturnType<typeof useCreateProposalMediasMutation>;
export type CreateProposalMediasMutationResult = Apollo.MutationResult<CreateProposalMediasMutation>;
export type CreateProposalMediasMutationOptions = Apollo.BaseMutationOptions<CreateProposalMediasMutation, CreateProposalMediasMutationVariables>;
export const CreateShortProposalDocument = gql`
    mutation createShortProposal($pointOfSaleId: Int, $provinceNumber: Int, $displayedTitle: String, $products: [ShortProposalProductInput!]!, $isDirectBilling: Boolean!, $isTankDirectBilling: Boolean) {
  createShortProposal(
    pointOfSaleId: $pointOfSaleId
    provinceNumber: $provinceNumber
    displayedTitle: $displayedTitle
    products: $products
    isDirectBilling: $isDirectBilling
    isTankDirectBilling: $isTankDirectBilling
  ) {
    ...shortProposal
  }
}
    ${ShortProposalFragmentDoc}`;
export type CreateShortProposalMutationFn = Apollo.MutationFunction<CreateShortProposalMutation, CreateShortProposalMutationVariables>;

/**
 * __useCreateShortProposalMutation__
 *
 * To run a mutation, you first call `useCreateShortProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShortProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShortProposalMutation, { data, loading, error }] = useCreateShortProposalMutation({
 *   variables: {
 *      pointOfSaleId: // value for 'pointOfSaleId'
 *      provinceNumber: // value for 'provinceNumber'
 *      displayedTitle: // value for 'displayedTitle'
 *      products: // value for 'products'
 *      isDirectBilling: // value for 'isDirectBilling'
 *      isTankDirectBilling: // value for 'isTankDirectBilling'
 *   },
 * });
 */
export function useCreateShortProposalMutation(baseOptions?: Apollo.MutationHookOptions<CreateShortProposalMutation, CreateShortProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShortProposalMutation, CreateShortProposalMutationVariables>(CreateShortProposalDocument, options);
      }
export type CreateShortProposalMutationHookResult = ReturnType<typeof useCreateShortProposalMutation>;
export type CreateShortProposalMutationResult = Apollo.MutationResult<CreateShortProposalMutation>;
export type CreateShortProposalMutationOptions = Apollo.BaseMutationOptions<CreateShortProposalMutation, CreateShortProposalMutationVariables>;
export const GetRecommendedAssortmentDocument = gql`
    query GetRecommendedAssortment($id: Int!) {
  recommendedAssortment(pointOfSaleId: $id) {
    ...recommendedPackagedProduct
  }
}
    ${RecommendedPackagedProductFragmentDoc}`;

/**
 * __useGetRecommendedAssortmentQuery__
 *
 * To run a query within a React component, call `useGetRecommendedAssortmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedAssortmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedAssortmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRecommendedAssortmentQuery(baseOptions: Apollo.QueryHookOptions<GetRecommendedAssortmentQuery, GetRecommendedAssortmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecommendedAssortmentQuery, GetRecommendedAssortmentQueryVariables>(GetRecommendedAssortmentDocument, options);
      }
export function useGetRecommendedAssortmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecommendedAssortmentQuery, GetRecommendedAssortmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecommendedAssortmentQuery, GetRecommendedAssortmentQueryVariables>(GetRecommendedAssortmentDocument, options);
        }
export type GetRecommendedAssortmentQueryHookResult = ReturnType<typeof useGetRecommendedAssortmentQuery>;
export type GetRecommendedAssortmentLazyQueryHookResult = ReturnType<typeof useGetRecommendedAssortmentLazyQuery>;
export type GetRecommendedAssortmentQueryResult = Apollo.QueryResult<GetRecommendedAssortmentQuery, GetRecommendedAssortmentQueryVariables>;
export const GetReportingDocument = gql`
    query GetReporting {
  reporting {
    ...reporting
  }
}
    ${ReportingFragmentDoc}`;

/**
 * __useGetReportingQuery__
 *
 * To run a query within a React component, call `useGetReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReportingQuery(baseOptions?: Apollo.QueryHookOptions<GetReportingQuery, GetReportingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportingQuery, GetReportingQueryVariables>(GetReportingDocument, options);
      }
export function useGetReportingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportingQuery, GetReportingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportingQuery, GetReportingQueryVariables>(GetReportingDocument, options);
        }
export type GetReportingQueryHookResult = ReturnType<typeof useGetReportingQuery>;
export type GetReportingLazyQueryHookResult = ReturnType<typeof useGetReportingLazyQuery>;
export type GetReportingQueryResult = Apollo.QueryResult<GetReportingQuery, GetReportingQueryVariables>;
export const GetAllSlidesDocument = gql`
    query GetAllSlides($where: SlideWhereInput) {
  slides(where: $where) {
    ...slide
  }
}
    ${SlideFragmentDoc}`;

/**
 * __useGetAllSlidesQuery__
 *
 * To run a query within a React component, call `useGetAllSlidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSlidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSlidesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllSlidesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSlidesQuery, GetAllSlidesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSlidesQuery, GetAllSlidesQueryVariables>(GetAllSlidesDocument, options);
      }
export function useGetAllSlidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSlidesQuery, GetAllSlidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSlidesQuery, GetAllSlidesQueryVariables>(GetAllSlidesDocument, options);
        }
export type GetAllSlidesQueryHookResult = ReturnType<typeof useGetAllSlidesQuery>;
export type GetAllSlidesLazyQueryHookResult = ReturnType<typeof useGetAllSlidesLazyQuery>;
export type GetAllSlidesQueryResult = Apollo.QueryResult<GetAllSlidesQuery, GetAllSlidesQueryVariables>;
export const GetStaticSlidesDocument = gql`
    query GetStaticSlides {
  staticSlides {
    id
    type
    order
    imageId
    image {
      ...image
    }
  }
}
    ${ImageFragmentDoc}`;

/**
 * __useGetStaticSlidesQuery__
 *
 * To run a query within a React component, call `useGetStaticSlidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaticSlidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaticSlidesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStaticSlidesQuery(baseOptions?: Apollo.QueryHookOptions<GetStaticSlidesQuery, GetStaticSlidesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStaticSlidesQuery, GetStaticSlidesQueryVariables>(GetStaticSlidesDocument, options);
      }
export function useGetStaticSlidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStaticSlidesQuery, GetStaticSlidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStaticSlidesQuery, GetStaticSlidesQueryVariables>(GetStaticSlidesDocument, options);
        }
export type GetStaticSlidesQueryHookResult = ReturnType<typeof useGetStaticSlidesQuery>;
export type GetStaticSlidesLazyQueryHookResult = ReturnType<typeof useGetStaticSlidesLazyQuery>;
export type GetStaticSlidesQueryResult = Apollo.QueryResult<GetStaticSlidesQuery, GetStaticSlidesQueryVariables>;
export const GetStaticSlideTypesDocument = gql`
    query GetStaticSlideTypes($where: StaticSlideTypeWhereInput, $orderBy: StaticSlideTypeOrderByWithRelationInput) {
  staticSlideTypes(where: $where, orderBy: $orderBy) {
    id
    type
    order
    editable
    productCategory
    staticSlides {
      id
      image {
        ...image
      }
      type
      order
      enabledByDefault
      brandId
      typeId
    }
  }
}
    ${ImageFragmentDoc}`;

/**
 * __useGetStaticSlideTypesQuery__
 *
 * To run a query within a React component, call `useGetStaticSlideTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaticSlideTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaticSlideTypesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetStaticSlideTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetStaticSlideTypesQuery, GetStaticSlideTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStaticSlideTypesQuery, GetStaticSlideTypesQueryVariables>(GetStaticSlideTypesDocument, options);
      }
export function useGetStaticSlideTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStaticSlideTypesQuery, GetStaticSlideTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStaticSlideTypesQuery, GetStaticSlideTypesQueryVariables>(GetStaticSlideTypesDocument, options);
        }
export type GetStaticSlideTypesQueryHookResult = ReturnType<typeof useGetStaticSlideTypesQuery>;
export type GetStaticSlideTypesLazyQueryHookResult = ReturnType<typeof useGetStaticSlideTypesLazyQuery>;
export type GetStaticSlideTypesQueryResult = Apollo.QueryResult<GetStaticSlideTypesQuery, GetStaticSlideTypesQueryVariables>;
export const GetAllTrainingDocument = gql`
    query GetAllTraining {
  trainings {
    ...training
  }
}
    ${TrainingFragmentDoc}`;

/**
 * __useGetAllTrainingQuery__
 *
 * To run a query within a React component, call `useGetAllTrainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTrainingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTrainingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTrainingQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTrainingQuery, GetAllTrainingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTrainingQuery, GetAllTrainingQueryVariables>(GetAllTrainingDocument, options);
      }
export function useGetAllTrainingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTrainingQuery, GetAllTrainingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTrainingQuery, GetAllTrainingQueryVariables>(GetAllTrainingDocument, options);
        }
export type GetAllTrainingQueryHookResult = ReturnType<typeof useGetAllTrainingQuery>;
export type GetAllTrainingLazyQueryHookResult = ReturnType<typeof useGetAllTrainingLazyQuery>;
export type GetAllTrainingQueryResult = Apollo.QueryResult<GetAllTrainingQuery, GetAllTrainingQueryVariables>;
export const GetAllUsersDocument = gql`
    query GetAllUsers($where: AdminUsersWhereInput) {
  users(where: $where) {
    ...user
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;